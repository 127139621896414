import React from "react";
import { connect } from 'react-redux';
import { compose } from "redux";
import { Table, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import moment from 'moment';

import { apiService } from '../../services/api_service'
import { alertActions } from '../../actions/alert-action';
class KitsTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            show1: false,
            kit_id: '',
            trackingHistory: [],
            history_width: '0%',
            open: true,
            loading: false,
            childLoader: false,
            show2: false,
            edit1: false,
            edit_user: {},
            showAlert: false,
            submitted: false,
            confirm_delete: false,
            released_user_id: {},
            release_dna: false,
            release_epi: false,
            release_dnaraw: false,
            check_error: false,
            check_user_error: false,
            release_loading: false,
            confirm_permission: false,
            extra: 0,
            user: [],
            departments: [],
            clinics: [],
            practitioner_email: false,
            user_mail: true,
            confirm_app_access: false,
            add_child_kit: false,
            child_kit: "",
            kitErrorMsg: "",
            error: { child_kit: false },
            relation: []
        }
    }

    async componentDidMount() {
    }

    handleClose = () => this.setState({ show: false })

    handleOpen = () => {
        let error = false;
        if (!this.state.release_dna && !this.state.release_epi && !this.state.release_dnaraw) {
            this.setState({ check_error: true });
            error = true;
        }
        if (!this.state.user_mail && !this.state.practitioner_email) {
            this.setState({ check_user_error: true });
            error = true;
        }
        if (!error) {
            this.setState({ open: !this.state.open, check_error: false, check_user_error: false });
        }
    }


    handleClose1 = () => {
        this.setState({
            showView: false,
        })
    }
    handleChange = (event) => {
        console.log("handleChange", event.target.value)
        this.setState({ child_kit: event.target.value })
        this.kitIdValidateHandler(event.target.value)
    }

    async kitIdValidateHandler(child_kit) {
        const { t } = this.props;
        var regexKit = /^(?=.{4,})(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
        if (!child_kit || !child_kit.match(regexKit)) {
            this.state.error.child_kit = true
            this.setState({ error: this.state.error, kitErrorMsg: t('validations.kit_id') })
        } else {

            let valid = await apiService.KitIdValidator(child_kit)
            console.log("valid", valid)
            if (valid) {
                this.state.error.child_kit = false
                this.setState({ error: this.state.error, kitErrorMsg: "" })
            } else {
                this.state.error.child_kit = true
                this.setState({ kitErrorMsg: t('validations.invalid_child_kit'), error: this.state.error })
            }

        }

    }

    handleShow = (kit) => {
        this.setState({ showView: true })
    }
    closeViewModal = () => {
        this.setState({ showView: false })
    }
    confirmDelete = async () => {
        this.setState({ submitted: true })
        let body = {
            user_id: Buffer.from(String(this.state.edit_user._id)).toString('base64'),

        }
        await apiService.DeleteUser(body)
        this.props.getAllUsers()
        this.setState({ confirm_delete: false, submitted: false })
        this.props.updateUserDetails(this.state.edit_user, 'delete')

    }
    updateUser = async (body) => {
        this.setState({ submitted: true })
        body.user_id = Buffer.from(String(this.state.edit_user._id)).toString('base64')

        let updatedData = await apiService.UpdateUserDetails(body)
        this.props.getAllUsers()
        if (!updatedData) {
            this.setState({ submitted: false, showAlert: true })
        } else {
            this.setState({ showAlert: false, edit1: false, submitted: false, show2: false })
            this.props.updateUserDetails(updatedData, 'update')
        }

    }

    clearAlert() {
        this.setState({ showAlert: false })
    }

    componentDidUpdate() {
        if (this.state.showAlert === true) {
            setTimeout(() => {
                this.clearAlert();
            }, 3000)
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="cmnTable">
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('kit_id')}</th>
                            <th>{t('upload_date')}</th>
                            <th>{t('assigned')}</th>
                            <th>{t('results_available')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props.loader && this.props.kitDetails.length <= 0 ? <tr style={{ textAlign: "center" }}><td colSpan={10}><h5>Kits are not present</h5></td></tr> : null}
                        {!this.props.loader && this.props.kitDetails.map((kit) => (
                            <tr key={kit._id}>
                                <td>{kit.kit_id}</td>
                                <td>{moment(kit.createdAt).format('DD-MM-YYYY')}</td>
                                <td>{kit.assigned_status == 1 ? t('assigned') : t('unassigned')}</td>
                                <td>{kit.result_status == 1 ? t('available') : t('not_available')}</td>
                            </tr>
                        ))}


                    </tbody>
                </Table>
                {this.props.loader && <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} />}
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    success: alertActions.success,
    error: alertActions.error

}
export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(KitsTable);
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import { apiService } from "../../services/api_service";
import { alertActions } from "../../actions";
import { store } from "../../helpers";
import { regexKit } from "../../constants";

class Bloodmodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show1: props.show ? props.show : false,
      bloodKitId: "",
      error: {
        bloodKitId: false,
      },
      bloodKitErrMsg: this.props.t('validations.kit_id'),
      loader: false,
      showAlert: false,
      alert: {
        message: "Error while update user",
        type: "alert-danger",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.bloodKitIdValidateHandler = this.bloodKitIdValidateHandler.bind(this);
    this.errorValidation = this.errorValidation.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let updateValue = value.trim()?.toUpperCase();

    if (/^[a-zA-Z0-9]*$/.test(updateValue)) {
      this.setState({ [name]: updateValue });
    }

    setTimeout(() => {
      this.bloodKitIdValidateHandler();
    }, 5);
  }

  async bloodKitIdValidateHandler(isCallFromHandleSubmit = false) {
    const { bloodKitId } = this.state;

    if (!regexKit.test(bloodKitId)) {
      const updatedError = {
        ...this.state.error,
        bloodKitId: true,
      };
      this.setState({
        error: updatedError,
        bloodKitErrMsg: this.props.t('validations.kit_id'),
      });
      return;
    }

    if (isCallFromHandleSubmit) return;

    const valid = await apiService.BloodKitIdValidator({
      //practitioner_id,
      kit_id: bloodKitId,
    });

    console.log("blood kit validator", valid);


    const updatedError = { ...this.state.error };

    let updatedBloodKitErrMsg = "";

    // if (["Kit ID is already registered", "Invalid Kit ID"].includes(valid?.message)) {
    if (!valid.success) {
      updatedError.bloodKitId = true;
      updatedBloodKitErrMsg = valid?.message;
    } else {
      updatedError.bloodKitId = false
      updatedBloodKitErrMsg = ""
    }

    this.setState({
      error: updatedError,
      bloodKitErrMsg: updatedBloodKitErrMsg,
    });
  }

  errorValidation() {
    let error_states = Object.keys(this.state.error).find(
      (key) => this.state.error[key] === true
    );
    if (error_states) {
      return false;
    } else {
      return true;
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.bloodKitIdValidateHandler(true);

    if (this.errorValidation()) {
      if (this.state.bloodKitId) {
        const body = {
          kit_id: this.state.bloodKitId?.toUpperCase(),
        };
        this.setState({ submitted: true });

        let bloodkitResp = await apiService.CreateBloodKit(body);
        console.log({ bloodkitResp });

        this.setState({ submitted: false });
        this.props.closeModal();
        if (bloodkitResp.success) {
          this.props.updateAPI();
          setTimeout(() => {
            store.dispatch(alertActions.success(this.props.t('messages.success.kit_add')));
          }, 1500);
        } else {
          setTimeout(() => {
            store.dispatch(
              alertActions.error(bloodkitResp.message || this.props.t('messages.error.kit_add'))
            );
          }, 1500);
        }
      }
    }
  }
  handleClose1 = () => {
    this.setState({
      bloodKitId: "",
      error: {
        bloodKitId: false,
      },
    });
    this.props.closeModal();
  };
  handleShow1 = () => this.setState({ show1: true });
  handleClose = () => {
    this.props.clearAlert();
  };

  render() {
    const { error, bloodKitId, bloodKitErrMsg, showAlert, alert } = this.state;
    const { show, title, t } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={this.handleClose1}
          centered
          className="userModal"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form autoComplete="off">
              {showAlert && (
                <div
                  className={`alert ${alert.type}`}
                  style={{ marginBottom: 15 }}
                >
                  {alert.message}
                  <button className="close" onClick={this.handleClose}>
                    x
                  </button>
                </div>
              )}
              <>
                <Form.Control
                  type="text"
                  placeholder={t('placeholder.enter_blood_kit')}
                  name="bloodKitId"
                  value={bloodKitId}
                  onChange={this.handleChange}
                  onInput={(e) => regexKit.test(e.target.value)}
                />
                <Form.Text className="text-muted">
                  {error.bloodKitId ? bloodKitErrMsg : null}
                </Form.Text>
              </>
              <Button
                className="continuebtn"
                type="submit"
                size="sm"
                onClick={this.handleSubmit}
              >
                {this.state.submitted ? (
                  <Spinner
                    className="spinnerClass"
                    animation="border"
                    variant="light"
                    size="sm"
                    style={{ float: "center" }}
                  />
                ) : t('buttons.submit')
                }
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(Bloodmodal);
import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";

function CustomerKitType({
  error,
  kitId,
  kitErrorMsg,
  handleChange,
  type,
  relation,
  blood_test_access,
  bloodKitId,
  bloodKitErrMsg,
  showGeneticKit,
  showBloodKit,
  handleGeneticKitClick,
  handleBloodKitClick,
  relationbloodKits,
}) {
  const { t } = useTranslation();

  /* Genetic Kit component config start*/
  const renderGeneticKitId = useMemo(() => {
    const geneticKitComp = (
      <>
        <Form.Control
          type="text"
          placeholder={t('placeholder.genetic_kit')}
          name="kitId"
          value={kitId}
          onInput={handleChange}
          disabled={type === "edit"}
        />
        <Form.Text className="text-muted">
          {error.kitId ? kitErrorMsg : null}
        </Form.Text>
      </>
    );

    const geneticKitIdWithLable = (
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{blood_test_access ? t('labels.genetic_kit_id') : t('labels.kit_id')}</Form.Label>
        {geneticKitComp}
      </Form.Group>
    );

    const renderGeneticChildKits = relation?.[0]?.children?.map(
      (child, index) => (
        <Form.Group controlId="formBasicEmail">
          <Form.Label>{t('labels.child_kit')} {index + 1}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t('placeholder.child_kit')}
            name={`child_kitId${index + 1}`}
            value={child.kit_id}
            onChange={handleChange}
            disabled={type === "edit" ? true : null}
          />
        </Form.Group>
      )
    );

    if (type === "edit") {
      return (
        <>
          {geneticKitIdWithLable}
          {renderGeneticChildKits}
        </>
      );
    }

    if (blood_test_access && type === "create") {
      return showGeneticKit ? geneticKitComp : null;
    }

    return geneticKitIdWithLable;
  }, [
    blood_test_access,
    error.kitId,
    handleChange,
    kitErrorMsg,
    kitId,
    relation,
    showGeneticKit,
    type
  ]);
  /* Genetic Kit component config end*/

  /* Blood Kit component config start*/

  const renderBloodKit = useMemo(() => {
    const bloodKitComp = (
      <>
        <Form.Control
          type="text"
          placeholder={t('placeholder.enter_blood_kit')}
          name="bloodKitId"
          value={bloodKitId}
          onInput={handleChange}
          disabled={type === "edit"}
        />
        <Form.Text className="text-muted">
          {error.bloodKitId ? bloodKitErrMsg : null}
        </Form.Text>
      </>
    );

    const bloodKitIdWithLable = (
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{t('labels.blood_kit_id')}</Form.Label>
        {bloodKitComp}
      </Form.Group>
    );

    const renderBloodChildKits = relationbloodKits?.map((child, index) => (
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{t('labels.child_kit')} {index + 1}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('placeholder.child_kit')}
          name={`child_kitId${index + 1}`}
          value={child}
          onChange={handleChange}
          disabled={type === "edit" ? true : null}
        />
      </Form.Group>
    ));

    if (blood_test_access && type === "create") {
      return showBloodKit ? bloodKitComp : null;
    }

    if (type === "edit") {
      return (
        <>
          {bloodKitIdWithLable}
          {renderBloodChildKits}
        </>
      );
    }

    return bloodKitIdWithLable;
  }, [
    bloodKitErrMsg,
    bloodKitId,
    blood_test_access,
    error.bloodKitId,
    handleChange,
    relationbloodKits,
    showBloodKit,
    type,
  ]);

  /* Blood Kit component config start*/
  return useMemo(() => {
    const renderBloodAccess = (
      <>
        <Form.Label>{t('labels.customer_type')}</Form.Label>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formGeneticKit">
              <Form.Check
                type="checkbox"
                label={t('labels.genetic')}
                checked={showGeneticKit}
                id="genetic-kit"
                name="geneticCheck"
                onChange={handleGeneticKitClick}
              />
              {renderGeneticKitId}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formBloodKit">
              <Form.Check
                type="checkbox"
                label={t('labels.blood')}
                checked={showBloodKit}
                id="blood-kit"
                name="bloodCheck"
                onChange={handleBloodKitClick}
              />
              {renderBloodKit}
            </Form.Group>
          </Col>
        </Row>
        <Form.Text className="text-muted">
          {error.kitIdSelect ? t('validations.select_option') : null}
        </Form.Text>
      </>
    );

    if (type === "edit") {
      if (bloodKitId && kitId) {
        return (
          <>
            {renderGeneticKitId}
            {renderBloodKit}
          </>
        );
      }

      if (bloodKitId && !kitId) {
        return renderBloodKit;
      }

      if (!bloodKitId && kitId) {
        return renderGeneticKitId;
      }
    }

    if (type === "create") {
      return blood_test_access ? renderBloodAccess : renderGeneticKitId;
    }

    return <></>;
  }, [
    bloodKitId,
    blood_test_access,
    error.kitIdSelect,
    handleBloodKitClick,
    handleGeneticKitClick,
    kitId,
    renderBloodKit,
    renderGeneticKitId,
    showBloodKit,
    showGeneticKit,
    type,
  ]);
}

export default withTranslation()(CustomerKitType);

import React from "react";
import Select from 'react-select';
import { Spinner, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { alertActions, currentActions } from "../../actions";
import { apiService } from '../../services/api_service';
import { handleDownloadFile } from "../../../src/helpers/handleDownloadFile"
import { IsExampleReportViewable } from "../../helpers/partner"
import { practitionerReportTabs } from "../../constants/general-constants";

import PageHead from "../../components/PageHead/PageHead";
import TabItems from "../../components/TabItems/TabItems";
import ReportSummary from "./ReportSummary";
import ReportDetails from "./ReportDetails";
import OverviewDetails from "./OverviewDetails";
import WarningIntro from "./warningIntro";
import Disclaimer from "./disclaimer";
class DiseaseRiskReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: false,
      users_list: [],
      report_list: [],
      report: [],
      report_sum: 0,
      summary: [],
      currentUser: null,
      message: this.props.t('messages.select_patients'),
      tabs: [],
      practitioner_id: this.props.user?.id,
      category: '',
      subCategory: '',
      select: practitionerReportTabs()[0],
      error_display: false,
      dropdown: practitionerReportTabs(),
      access: {},
      overview: [],
      overview_head: [],
      tab_groups: [],
      showSelect: false,
      showIntro: true,
      showDisclaimer: true,
      title: "",
      clearUser: false,
      showPdfButton: false,
      pdfButtonTitle: this.props.t('buttons.export_disease_pdf')
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
      this.setState({ loader: true });
      try {
        let practitioner_details = nextProps.user;
        this.setState({
          practitioner_id: Buffer.from(String(practitioner_details._id)).toString("base64"),
          access: practitioner_details.access_controls.Disease_Risk_Report,
          loader: false,
          ...nextProps.user
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  async GetTabGroups(user_id) {
    if (!this.props.user?._id || !user_id) return
    try {
      this.setState({ loader: true })
      if (user_id == 'select') {
        this.setState({ loader: false, tab_groups: [], tabs: [], message: this.props.t('messages.select_patients'), currentUser: null, error_display: false })
        return

      }
      let result = await apiService.DiseaseRiskTabGroup(
        btoa(user_id),
      );
      if (result.length > 0) {
        let tabs = result.map(t => t.title);

        this.setState({
          tab_groups: result,
          tabs,
          loader: false,
          currentUser: user_id,
        }, () => {
          this.GetOverview(result[0]);
        });
      } else {
        this.setState({ tab_groups: [], loader: false, message: this.props.t('no_result_available'), })
      }
    } catch (error) {
      this.setState({ loader: false })
      console.log("error", error)
    }
  }

  async GetOverview(tab) {
    this.setState({ loader: true })

    const { currentUser, practitioner_id } = this.state;
    const user_id = Buffer.from(String(currentUser)).toString('base64');
    let children = tab.children;
    const data = tab.children.map(c => c.key);

    let overview = await apiService.DiseaseRiskOverview(user_id, data)
    if (!overview.success) {
      let message = overview.message || 'No Results found'
      if (overview.status == 500)
        message = "Error in generating report"
      this.setState({ loader: false, message, error_display: true })
      return;
    } else {
      this.setState({ clearUser: false })
      overview = overview.data;
    }
    if (overview && overview.length > 0) {
      let availableTabs = overview.map(o => o.type)
      children = children.filter(ch => availableTabs.includes(ch.key))
      this.setState({
        category: tab.title,
        subCategory: '',
        overview,
        report_list: children,
        showSelect: false,
        select: practitionerReportTabs()[0],
        error_display: false,
        loader: false,
        title: tab.title.replace("_", " ").toLocaleUpperCase(),
        showPdfButton: true
      });
    } else {
      this.setState({
        loader: false,
        message: this.props.t('no_user_result'),
        error_display: true,
        showPdfButton: false
      });
    }
  }

  async GetDiseaseRiskReport(category) {
    const { practitioner_id, currentUser } = this.state;
    let result = await apiService.DiseaseRiskReport(currentUser, category)
    if (result.data) {
      let summary_num = result.data.reduce(function (sum, item) {
        if (item.value.num !== undefined && item.value.num !== null) {
          return sum + item.value.num
        } else {
          return sum + 0
        }
      }, 0)
      let summary = await apiService.DiseaseRiskSummary(category, summary_num)
      this.setState({ report: result.data, report_sum: summary_num, error_display: false, summary })
    } else {
      this.setState({ message: this.props.t('no_user_result'), error_display: true, report: [], summary: [] })
    }
  }

  async changeCategory(category) {
    const { tab_groups } = this.state;
    let selectedTab = tab_groups.find(t => t.title == category);

    if (selectedTab.children && selectedTab.children.length) {
      await this.GetOverview(selectedTab);
    }
  }

  async selectSubCategory(subCategory) {
    this.setState({ loader: true })
    await this.GetDiseaseRiskReport(subCategory.key);
    this.setState({
      showSelect: true,
      select: { label: this.props.t('pr_tabs.summary'), value: 3 },
      subCategory: subCategory.key,
      loader: false,
      title: subCategory.title.replace("_", " ").toLocaleUpperCase()
    })
  }

  handleSelect(selected) {
    console.log("value", selected);
    let updateState = { select: selected };
    if (selected.value == 1) {
      updateState.showSelect = false;
      this.handleTitle("Disease Risk Report");
    }
    this.setState(updateState);
  }

  async AcceptIntro() {
    this.props.removeCurrentUser()
    setTimeout(() => {
      this.setState({ showIntro: false });
    }, 0);
  }

  RejectIntro() {
    const { history } = this.props;
    if (history) history.goBack();
  }

  async AcceptDisclaimer() {
    this.props.removeCurrentUser()
    setTimeout(() => {
      this.setState({ showDisclaimer: false });
    }, 0);
  }

  RejectDisclaimer() {
    const { history } = this.props;
    if (history) history.goBack();
  }

  handleTitle() {
    this.setState({ title: "" })
  }

  async handleExportDiseaseRiskPDF(data) {
    try {
      let result = await apiService.DiseaseRiskRelease(data);
    } catch (error) {
      console.log("Error in disease risk pdf", error)
      throw error;
    }
  }

  handleViewExample() {
    const sourcePath = "pdf/DNA_reports/diseaseRisk.pdf";
    const destinationPath = "download";
    const fileName = "sample_disease_risk_report.pdf";
    handleDownloadFile(sourcePath, destinationPath, fileName);
  }

  render() {
    const { t } = this.props;
    const { loader, tabs, users_list, report_list, category,
      report, dropdown, select, message, report_sum, currentUser,
      error_display, summary, overview, showSelect, showIntro, showDisclaimer, title, clearUser,
      showPdfButton, pdfButtonTitle } = this.state;
    const introModal = (
      <Modal show={this.state.showIntro} centered className="trackingModal" size="sm">
        <Modal.Header >
          <Modal.Title>{t('title.warning_caps')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WarningIntro></WarningIntro>
          <div className="row">
            <div className="col-12 col-md-8 mt-4">
              {IsExampleReportViewable(this.state.partner_id) ?
                <button className="infobtn-view" onClick={this.handleViewExample}>{t('buttons.view_example_report')}</button>
                : ''}
            </div>
            <div className="col-12 col-md-4 text-end mt-4">
              <button className={"continuebtn-yes width-100px d-inline m-1"} onClick={() => this.AcceptIntro()}>{t('buttons.yes')}</button>
              <button className={"continuebtn-no width-100px d-inline m-0"} onClick={() => this.RejectIntro()}>{t('buttons.no')}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
    const disclaimerModal = (
      <Modal show={this.state.showDisclaimer} centered className="trackingModal" size="sm">
        <Modal.Header >
          <Modal.Title>{t('title.disclaimer')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Disclaimer></Disclaimer>
          <button className={"continuebtn-yes"} onClick={() => this.AcceptDisclaimer()}>{t('buttons.yes')}</button>
          <button className={"continuebtn-no"} onClick={() => this.RejectDisclaimer()}>{t('buttons.no')}</button>
        </Modal.Body>
      </Modal>
    );
    return (
      <>
        <div className="dnaReport-wrp">
          {this.state.showIntro ? introModal : null}
          {this.state.showDisclaimer && this.state.showIntro ? disclaimerModal : null}

          {!this.state.showIntro && !this.state.showDisclaimer && !this.state.show && (
            <PageHead
              title={t('title.disease_risk_report')}
              UserList={users_list}
              DetailsGetAPI={this.GetTabGroups.bind(this)}
              page={"Disease_risk"}
              clearUser={clearUser}
              handleTitle={this.handleTitle.bind(this)}
              showReportButton={showPdfButton}
              reportButtonTitle={pdfButtonTitle}
              reportButtonCallback={this.handleExportDiseaseRiskPDF.bind(this)}
              reportType={"disease_risk"}
            ></PageHead>
          )}

          <TabItems
            tab={tabs}
            categorySelector={this.changeCategory.bind(this)}
            category={category.replace(/_/g, " ")}
          ></TabItems>
          <div className="selectionBar">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-auto">
                <h3>
                  {title ? title.replace(/_/g, " ") : t('title.disease_risk_report')}
                </h3>
              </div>
              {showSelect ? (
                <div className="col-12 col-sm-auto">
                  <Select
                    onChange={(res) => this.handleSelect(res)}
                    defaultValue={dropdown[0]}
                    value={select}
                    options={dropdown}
                    menuPlacement="auto"
                    isSearchable={false}
                    className="customSelect-wrp"
                    classNamePrefix="customSelect"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="diseaseRiskReport">
            {loader ? (
              <Spinner
                className="spinnerClass"
                animation="border"
                variant="info"
                style={{
                  marginLeft: "50%",
                  padding: "20px",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              />
            ) : currentUser && !error_display ? (
              select.value === 3 ? (
                <ReportSummary
                  num={report_sum}
                  data={report}
                  summary={summary}
                />
              ) : select.value === 2 ? (
                <ReportDetails data={report} />
              ) : (
                <OverviewDetails
                  overview={overview}
                  tabs={report_list}
                  categorySelector={this.selectSubCategory.bind(this)}
                />
              )
            ) : (
              <h5 style={{ textAlign: "center", padding: "20px" }}>
                {message}
              </h5>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const mapDispatchToProps = {
  success: alertActions.success,
  addCurrentUser: currentActions.add,
  removeCurrentUser: currentActions.remove
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation()
)(DiseaseRiskReport);

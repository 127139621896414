import axios from 'axios';
import config from '../config/config';
import { authHeader, store } from '../helpers';
import { userActions } from '../actions';

export const userService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    otp,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    practitioner_logout
};

async function login(username, password) {
    const LANG = localStorage.getItem("userLanguage") || "en";
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "lang": LANG
        },
        body: JSON.stringify({ email: username, password: password })
    };
    // console.log(requestOptions,'requestOptions')

    const response = await fetch(`${config.apiUrl}practitioner/login`, requestOptions);
    const user = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if (user.data) {
        return user.data;
    } else {
        throw user.message;
    }
}

async function logout(page, alert) {
    // remove user from local storage to log user out
    await practitioner_logout(page, alert)
}

async function forgotPassword(username) {
    const LANG = localStorage.getItem("userLanguage") || "en";
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "lang": LANG
        },
        body: JSON.stringify({ email: username })
    };
    console.log(requestOptions, 'requestOptions')

    const response = await fetch(`${config.apiUrl}practitioner/forgotpassword`, requestOptions);
    const forgot = await handleResponse(response);
    if (forgot.statusCode === 200) {
        return forgot;
    } else {
        throw forgot.message;
    }
}

async function resetPassword(password, token) {
    try {
        const LANG = localStorage.getItem("userLanguage") || "en";
        const body = JSON.stringify({ password });

        let res = await axios.post(
            `${config.apiUrl}practitioner/resetpassword`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "lang": LANG
                },
            }
        );
        return res;

    } catch (error) {
        return error?.response;
    }
}

async function otp(otp, token) {
    const LANG = localStorage.getItem("userLanguage") || "en";
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            "lang": LANG
        },
        body: JSON.stringify({ OTP: otp })

    };
    // console.log(requestOptions,'requestOptions')

    const res = await fetch(`${config.apiUrl}practitioner/verifyOTP`, requestOptions);
    const responseBody = await res.json();


    if (res.status === 400) {
        console.log("Response Body:", res.statusText, responseBody);
        throw responseBody;
    }

    let userDetails = responseBody.data
    const userItem = { user_name: userDetails.user_name, name: userDetails.first_name + " " + userDetails.last_name, email: userDetails.email, type: userDetails.type, id: btoa(userDetails._id), token: userDetails.token, partner_id: userDetails.partner_id }
    sessionStorage.setItem('token', userItem.token)
    return responseBody;
}

async function getAll() {
    const LANG = localStorage.getItem("userLanguage") || "en";
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "lang": LANG }
    };

    const response = await fetch(`${config.apiUrl}/users`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const LANG = localStorage.getItem("userLanguage") || "en";
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "lang": LANG }
    };

    const response = await fetch(`${config.apiUrl}/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function register(user) {
    const LANG = localStorage.getItem("userLanguage") || "en";
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "lang": LANG
        },
        body: JSON.stringify(user)
    };

    const response = await fetch(`${config.apiUrl}/users/register`, requestOptions);
    return handleResponse(response);
}

async function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    const response = await fetch(`${config.apiUrl}/users/${user.id}`, requestOptions);
    return handleResponse(response);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const LANG = localStorage.getItem("userLanguage") || "en";
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), "lang": LANG }
    };

    const response = await fetch(`${config.apiUrl}/users/${id}`, requestOptions);
    return handleResponse(response);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].includes(response.status)) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

async function practitioner_logout(page = "", alert = () => { }) {
    let res = { status: 204 };

    const LANG = localStorage.getItem("userLanguage") || "en";
    const token = sessionStorage.getItem('token');
    if (token) {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "lang": LANG
            },
        };

        res = await fetch(`${config.apiUrl}practitioner/logout`, requestOptions);
    }

    if (res.status === 204) {
        sessionStorage.clear();
        localStorage.clear();
        store.dispatch(userActions.logout());

        if (!page) page = "/signin";

        setTimeout(() => {
            alert();
        }, 10);

        window.history.pushState({}, "", page);
        window.location.reload(page);
        return true;
    }

    return false;
}

import { React, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Nav, Collapse } from "react-bootstrap";

import { apiService } from "../../services/api_service";
import PDFViewer from "../Modal/PdfModal";
import TrainingPdf from "../Modal/TrainingModal";
import { alertActions, userActions } from "../../actions";
import { userService } from "../../services";
import {
  CustomersIcon, BloodTestIcon, ClinicIcon, CustomFilterIcon, DnaReportsIcon,
  DnaIcon, HealthInsightIcon, EpiReportIcon, BloodResultIcon, QuestionnairesIcon,
  QDnaIcon, QEpiIcon, QEpiFolllowUpIcon, VirusRiskScoreIcon, LifestyleDataIcon,
  PractitionerReportIcon, BusinessInfoIcon, ManagementInsightsIcon, HelpIcon,
  UsageBillingIcon, CognitiveSkillsIcon, CognitiveDomainIcon, CognitiveResultIcon,
  FaceScanResultIcon, UserGuideIcon, TrainingGuideIcon, DiseaseRiskIcon,
  CongenitalReportIcon, BloodKitsIcon, Dna12Icon, ArrowIcon
} from "../Icons/SideMenuIcons"


const SideMenu = (props) => {

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [current, setCurrent] = useState(props.path)
  const [access, setAccess] = useState({})
  const [questions, setQuestions] = useState({})
  const type = props?.user?.type || ""
  const user = props?.user || {}

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const validateToken = async () => {
      if (token) {
        const response = await apiService.TokenValidate({
          type: "TOKEN",
          authorizationToken: `Bearer ${token}`,
        });
        return response;
      }
      return false;
    }

    validateToken().then((validate_token) => {
      if (!validate_token) {
        userService.logout();
      }
    });
  }, []);

  useEffect(async () => {
    let usertoken = sessionStorage.getItem('token');
    if (usertoken) {
      let sample = {};

      await apiService.practitionerDetails().then((res) => {
        Object.entries(res?.access_controls).map((items) => {
          sample[items[0]] = false;
          if (items[0] == "Questionnaires") {
            setQuestions(items[1]);
          }
          if (typeof items[1] !== "boolean") {
            Object.entries(items[1]).map((item) => {
              if (item[1] == true) {
                sample[items[0]] = item[1];
              }
            });
          } else {
            sample[items[0]] = items[1];
          }
        });
        setAccess(sample);
        Object.entries(sample).map((page) => {
          switch (page[0]) {
            case "DNA_results":
              if (!page[1] && current.includes("dnareport/dna")) {
                props.unauthorizePage();
              }
              break;
            case "Epigenetic_results":
              if (!page[1] && current.includes("epigeneticreport")) {
                props.unauthorizePage();
              }
              break;
            case "Practitioner_report":
              if (!page[1] && current.includes("practitionersreport")) {
                props.unauthorizePage();
              }
              break;
            case "Congenital_report":
              if (!page[1] && current.includes("congenitalreport")) {
                props.unauthorizePage();
              }
              break;
            case "Congenital_report": //TODO
              if (!page[1] && current.includes("diseaserisk")) {
                props.unauthorizePage();
              }
              break;
            case "Health_insights":
              if (!page[1] && current.includes("dnareport/healthinsight")) {
                props.unauthorizePage();
              }
              break;
            case "Questionnaires":
              if (!page[1] && current.includes("questionnaires")) {
                props.unauthorizePage();
              }
              break;
            case "lifestyle_data":
              if (!page[1] && current.includes("lifestyle")) {
                props.unauthorizePage();
              }
              break;
            case "business_information":
              if (!page[1] && current.includes("business_info")) {
                props.unauthorizePage();
              }
              break;
            case "management_insights":
              if (!page[1] && current.includes("management_insight")) {
                props.unauthorizePage();
              }
              break;
            case "404":
              if (!page[1] && current.includes("404")) {
                // props.unauthorizePage()
              }
              break;
            case "Virus_risk":
              if (!page[1] && current.includes("virusrisk")) {
                props.unauthorizePage();
              }
              break;

            case "billing":
              if (!page[1] && current.includes("billing")) {
                props.unauthorizePage();
              }
              break;
            case "blood_test":
              if (!page[1] && current.includes("blodd_test")) {
                props.unauthorizePage();
              }
              break;
            case "facial_analysis":
              if (!page[1] && current.includes("facescanreport")) {
                props.unauthorizePage();
              }
              break;
            case "cognitive_brain_assessment":
              if (!page[1] &&
                (current.includes("cognitiveskills") || current.includes("cognitivedomains"))) {
                props.unauthorizePage();
              }
              break
            case "dna12":
              if (!page[1] && current.includes("dna12")) {
                props.unauthorizePage();
              }
              break;
            default:
              // props.unauthorizePage()
              break;
          }
        });
      });

      if (current.includes("dnareport")) {
        setOpen1(true);
      } else if (current.includes("questionnaires")) {
        setOpen(true);
      } else if (current.includes("help")) {
        setOpen2(true);
      } else if (current.includes("blood")) {
        setOpen3(true);
      } else if (current.includes("cognitive")) {
        setOpen4(true);
      }
      else {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
      }
    }
  }, [current])

  const ShowModal = () => {
    setModal(!modal)
    setModal1(!modal1)
  }

  const hideHelpMenu = () => {
    if ((user.partner_id == "7")
      || (user.partner_id == "50")
      || (user.partner_id == "51")
      || props.i18n.language !== "en")
      return true

    return false
  }
  const { t } = props;

  return (
    <section className={props.className} style={{ top: props.top }}>
      <button className="menuclosebtn d-md-none">
        x
      </button>
      <Nav defaultActiveKey={current} className="flex-column">
        {
          access ?
            <>
              <Nav.Link href="/"><CustomersIcon />{t('sidemenu.customers')}</Nav.Link>
              {type !== "practitioner" ? <Nav.Link href="/usermanagement"><ClinicIcon />{t('sidemenu.clinic_practitioner_management')}</Nav.Link> : null}
              {type !== "practitioner" ? <Nav.Link href="/deptmanagement"><CustomFilterIcon />{t('sidemenu.custom_filters')}</Nav.Link> : null}
              {access.DNA_results || access.Health_insights ?
                <>
                  <Nav.Link onClick={() => setOpen1(!open1)}
                    aria-expanded={open1}>
                    <DnaReportsIcon />
                    {t('sidemenu.dna_reports')}  <span className="item-arrow"><ArrowIcon /></span>
                  </Nav.Link>
                  <Collapse className="menuDropdown" in={open1}>
                    <Nav defaultActiveKey={current}>
                      {access.DNA_results ? <Nav.Link href="/dnareport/dna"><DnaIcon />{t('sidemenu.dna')}</Nav.Link> : null}
                      {access.Health_insights ? <Nav.Link href="/dnareport/healthinsight"><HealthInsightIcon />{t('sidemenu.health_sights')} </Nav.Link> : null}
                    </Nav>
                  </Collapse>
                </>
                : null}

              {/* DNA12 */}
              {access.dna12 ? <Nav.Link href="/dna12"><Dna12Icon />{t('sidemenu.dna12')}</Nav.Link> : null}

              {/* Epigenetic Results */}
              {access.Epigenetic_results ? <Nav.Link href="/epigeneticreport"><EpiReportIcon />{t('sidemenu.epigenetic')}</Nav.Link> : null}

              {/* Blood Test */}
              {access.blood_test ?
                <>
                  <Nav.Link onClick={() => setOpen3(!open3)}
                    aria-expanded={open3}>
                    <BloodTestIcon />
                    {t('sidemenu.blood_tests')}  <span className="item-arrow"><ArrowIcon /></span>
                  </Nav.Link>
                  <Collapse className="menuDropdown" in={open3}>
                    <Nav defaultActiveKey={current}>
                      <Nav.Link href="/bloodresults"><BloodResultIcon />{t('sidemenu.blood_result')}</Nav.Link>
                      <Nav.Link href="/bloodkits"><BloodKitsIcon />{t('sidemenu.blood_kits')}</Nav.Link>
                    </Nav>
                  </Collapse>
                </>
                : null}

              {/* Questionnaire */}
              {access.Questionnaires ?
                <>
                  <Nav.Link onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}>
                    <QuestionnairesIcon /> {t('sidemenu.questionnaires')} <span className="item-arrow"><ArrowIcon /></span>
                  </Nav.Link>
                  <Collapse className="menuDropdown" in={open}>
                    <Nav defaultActiveKey={current}>
                      {questions.DNA_answers ? <Nav.Link href="/questionnaires/dna">
                        <QDnaIcon />

                        {t('sidemenu.questionnaire.dna')}</Nav.Link> : null}
                      {questions.Epigenetics_answers ? <Nav.Link href="/questionnaires/epi">
                        <QEpiIcon />
                        {t('sidemenu.questionnaire.epigenetic')} </Nav.Link> : null}
                      {questions.Epigenetics_follow_up_answers ? <Nav.Link href="/questionnaires/epifollow">
                        <QEpiFolllowUpIcon />
                        {t('sidemenu.questionnaire.epi_followup')} </Nav.Link> : null}
                    </Nav>
                  </Collapse>
                </>
                : null}

              {/* Face Age*/}
              {access.facial_analysis ? <Nav.Link href="/facescanreport">
                <FaceScanResultIcon />
                {t('sidemenu.face_results')}
              </Nav.Link> : null}

              {/* Brain Age*/}
              {access.cognitive_brain_assessment ?
                <>
                  <Nav.Link onClick={() => setOpen4(!open4)} aria-expanded={open4}>
                    <CognitiveResultIcon />
                    {t('sidemenu.cognitive_results')}
                    <span className="item-arrow"><ArrowIcon /></span>
                  </Nav.Link>
                  <Collapse className="menuDropdown" in={open4}>
                    <Nav defaultActiveKey={current}>
                      <Nav.Link href="/cognitivedomains">
                        <CognitiveDomainIcon />
                        {t('sidemenu.cognitive_domains')}
                      </Nav.Link>
                      <Nav.Link href="/cognitiveskills">
                        <CognitiveSkillsIcon />
                        {t('sidemenu.cognitive_skills')}
                      </Nav.Link>
                    </Nav>
                  </Collapse>
                </> : null}
              {access.lifestyle_data ? <Nav.Link href="/lifestyle" ><LifestyleDataIcon />{t('sidemenu.lifestyle')}</Nav.Link> : null}
              {access.Virus_risk ? <Nav.Link href="/virusrisk" ><VirusRiskScoreIcon />{t('sidemenu.virusrisk')}</Nav.Link> : null}
              {access.Practitioner_report ? <Nav.Link href="/practitionersreport"><PractitionerReportIcon />{t('sidemenu.practitioner_report')}</Nav.Link> : null}

              {access.Congenital_report ? <Nav.Link href="/congenitalreport"><CongenitalReportIcon />{t('sidemenu.congenital_report')}</Nav.Link> : null}

              {/** TODO */}
              {access.Disease_Risk_Report ? <Nav.Link href="/diseaserisk"><DiseaseRiskIcon />{t('sidemenu.disease_risk')}</Nav.Link> : null}

              {access.business_information ? <Nav.Link href="/business"><BusinessInfoIcon />{t('sidemenu.business_info')}</Nav.Link> : null}
              {access.management_insights ? <Nav.Link href="/management"><ManagementInsightsIcon />{t('sidemenu.management_insights')}</Nav.Link> : null}
              <>
                {hideHelpMenu() ? null :
                  <Nav.Link href="" onClick={() => setOpen2(!open2)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open2}>
                    <HelpIcon />{t('sidemenu.help')} <span className="item-arrow"><ArrowIcon /></span>
                  </Nav.Link>
                }
                <Collapse className="menuDropdown" in={open2}>
                  <Nav defaultActiveKey={current}>
                    <Nav.Link href="" onClick={() => setModal(!modal)}><UserGuideIcon />{t('sidemenu.user_guide')}</Nav.Link>
                    <Nav.Link href="" onClick={() => setModal1(!modal1)}><TrainingGuideIcon />{t('sidemenu.training_guide')}</Nav.Link>
                  </Nav>
                </Collapse>
              </>
              {access.billing ? <Nav.Link href="/billing"><UsageBillingIcon />{t('sidemenu.usage')}</Nav.Link> : null}
              {modal && <PDFViewer showpdf={modal} />}
              {modal1 && <TrainingPdf showpdf={modal1} />}
            </>
            : <h5>{t('contact_admin')}</h5>
        }
      </Nav>
    </section>

  );
};


function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
  logout: userActions.logout
}

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(SideMenu);
import React from "react";
import { Table, Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { apiService } from '../../services/api_service'
import { alertActions } from "../../actions";
import { getDnaSections } from "../../constants";

import ColorIndicator from "../../components/ColorIndicator/ColorIndicator";
import SelectionsBar from "../../components/SelectionsBar/SelectionsBar";
import PageHead from "../../components/PageHead/PageHead";

class DnaResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      users_list: [],
      loader: false,
      message: this.props.t('messages.select_patients'),
      dnaResult: [],
      practitioner_id: this.props.user?.id,
      email: this.props.user?.email,
      viewData: {},
      fullResult: [],
      sections: getDnaSections(),
      access: {},
      currentIndex: 0,
      clearUser: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow(result) {
    this.setState({ show: true, viewData: result });
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
      try {
        this.setState({ loader: true });
        const practitioner_id = btoa(nextProps.user._id);
        this.setState({
          practitioner_id: nextProps.user._id,
          loader: false,
          ...nextProps.user,
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        this.setState({ loader: false });
      }
    }
  }

  async getResult(user_id) {
    const { t } = this.props;
    if (!this.props.user?._id || !user_id) return
    let oldsection = getDnaSections();
    let access = this.state.access;
    if (
      this.state.access == undefined ||
      Object.keys(this.state.access).length <= 0
    ) {
      let practitioner_details = this.props.user;
      access = practitioner_details?.access_controls?.DNA_results;
    }
    let section = [];
    Object.entries(access).map((item) => {
      let key = item[0].replace(/dna_/i, "").toLocaleLowerCase();
      oldsection.map((list) => {
        console.log(key, list, key == list.label.toLocaleLowerCase());
        if (key == list.key.toLocaleLowerCase()) {
          if (item[1]) {
            section.push(list);
          }
        }
      });
    });

    this.setState({ loader: true, sections: section });
    if (user_id == "select") {
      this.setState({
        loader: false,
        message: t('messages.select_patients'),
        dnaResult: [],
        fullResult: [],
      });
      return;
    }
    const practitioner_id = btoa(this.props.user?._id);
    //console.log(this.state.practitioner_id);
    let DNA_result = await apiService.DNAResult(btoa(user_id));
    if (!DNA_result.success && DNA_result.message == "user not found") {
      this.setState({ clearUser: true, loader: false });
      return;
    } else {
      this.setState({ clearUser: false });
      DNA_result = DNA_result.data;
    }
    if (DNA_result && DNA_result.length > 0) {
      this.setState({
        loader: false,
        dnaResult: DNA_result[this.state.currentIndex],
        fullResult: DNA_result,
        sections: section,
      });
    } else {
      this.setState({
        loader: false,
        message: t('messages.error.dna_results_not_found'),
        dnaResult: [],
        fullResult: [],
        sections: section,
        access: access,
      });
    }
  }

  async selectedItemResult(index) {
    this.setState({ currentIndex: index });
    if (this.state.dnaResult.length > 0) {
      this.setState({ dnaResult: this.state.fullResult[index] });
    }
  }

  render() {
    const { t } = this.props;
    const { show, users_list, dnaResult, viewData,
      sections, message, clearUser, } = this.state;
    return (
      <>
        <div className="dnaReport-wrp">
          <PageHead
            title={t('title.dna_report')}
            UserList={users_list}
            page={"DNA_Report"}
            clearUser={clearUser}
            DetailsGetAPI={this.getResult.bind(this)}
          ></PageHead>

          <SelectionsBar
            list={sections}
            title={t('title.dna_results')}
            changeItem={this.selectedItemResult.bind(this)}
          ></SelectionsBar>

          <div className="DnaReporttable">

            <div className="cmnTable">
              {this.state.loader ? (
                <Spinner
                  animation="border"
                  variant="info"
                  className="spinnerClass"
                  style={{
                    marginLeft: "50%",
                    padding: "20px",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                />
              ) : dnaResult.length > 0 ? (
                <Table responsive striped>
                  <thead>
                    <tr style={{ color: "white" }}>
                      <th>{t('result_table.title')}</th>
                      <th>{t('result_table.indicator')}</th>
                      <th>{t('result_table.status')}</th>
                      <th>{t('result_table.action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dnaResult.map((result) => (
                      <tr key={result.category_key}>
                        <td>{camelize(result.title)}</td>
                        <td>
                          <ColorIndicator
                            num={result.indicator}
                          ></ColorIndicator>
                        </td>
                        <td style={{ fontWeight: "600" }}>
                          {camelize(result.status)}
                        </td>
                        <td>
                          <a
                            onClick={() => this.handleShow(result)}
                            style={{ cursor: "pointer" }}
                          >
                            {t('result_table.view_result')}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h5 style={{ textAlign: "center", padding: "20px" }}>
                  {message}
                </h5>
              )}
            </div>
          </div>
        </div>
        {viewData.title && (
          <Modal
            show={show}
            onHide={this.handleClose}
            centered
            className="cmnModal dnareportModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {camelize(viewData.title)}
                <span className="risktype" style={{ color: "#fc1504" }}>
                  {camelize(viewData.status)}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: viewData.intro }}></div>
              <hr></hr>
              <h6>{t('result_popup.genes_of_interest')}</h6>

              <span className="geneslabel">
                <div
                  dangerouslySetInnerHTML={{
                    __html: viewData.genes_of_interest,
                  }}
                ></div>
              </span>
              <h6>{t('result_popup.result')}</h6>
              <div
                dangerouslySetInnerHTML={{ __html: viewData.output.result }}
              ></div>
              <h6>{t('result_popup.recommendation')}</h6>
              <div
                dangerouslySetInnerHTML={{
                  __html: viewData.output.recommondation,
                }}
              ></div>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toUpperCase() : word.toUpperCase();
  }).replace(/\s+/g, ' ');
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success
}

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(DnaResult);

import React from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import { alertActions } from "../../actions";
import EditUser from '../../components/Modal/EditUser'
import { SortArray, RESULT_TYPE_KIT_STATUS } from "../../constants";

class PageFiltration extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show1: false,
      userDetails: {
        fname: '',
        lname: '',
        email: '',
        phnum: '',
        dob: '',
        kitId: '',
        partner: '',
        selected_dep: '',
        selected_clinic: '',
        practitioner: '',
        terms_condition: false,
        research: false,
        newsletter: false
      },
      loader: false,
      partnerList: [],
      practitionerList: [],
      subPartnerList: [],
      kitStatusList: [
        {
          label: this.props.t('kit_status.scanned'),
          value: "SCANNED"
        },
        {
          label: this.props.t('kit_status.received_at_hub'),
          value: "RECEIVED_AT_HUB"
        },
        {
          label: this.props.t('kit_status.received_at_lab'),
          value: "RECEIVED"
        },
        {
          label: this.props.t('kit_status.sample_initiated_dna'),
          value: "SAMPLE_INITIATED_DNA"
        },
        {
          label: this.props.t('kit_status.sample_initiated_epi'),
          value: "SAMPLE_INITIATED_EPI"
        },
        {
          label: this.props.t('kit_status.result_ready_dna'),
          value: "RESULT_READY_DNA"
        },
        {
          label: this.props.t('kit_status.result_ready_epi'),
          value: "RESULT_READY_EPI"
        },
        {
          label: this.props.t('kit_status.failed_qc_dna'),
          value: "FAILED_QC_DNA"
        },
        {
          label: this.props.t('kit_status.failed_qc_epi'),
          value: "FAILED_QC_EPI"
        },
      ],
      filterCondition: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose1 = this.handleClose1.bind(this)
    this.addUserHandler = this.addUserHandler.bind(this)

  }

  async selectHandler(e) {
    let type = e.target.name
    const practitioner_id = btoa(this.props.practitioner_id)
    let condition = { practitioner_id, ...this.state.filterCondition }

    if (type == 'practitioner') {
      if (e.target.value != 'select') {
        condition.practitionerID = btoa(e.target.value)
      } else {
        delete condition.practitionerID
      }
    }
    if (type == 'clinic') {
      if (e.target.value != 'select') {
        condition.clinic_id = btoa(e.target.value)
        await this.props.practitioners({ clinic_id: btoa(e.target.value) })
      } else {
        delete condition.clinic_id
        await this.props.practitioners({ practitioner_id })
      }
    }
    if (type == 'admin') {
      if (e.target.value != 'select') {
        condition.partner_id = e.target.value
        delete condition.clinic_id
        delete condition.practitionerID
        await this.props.subPartners({ practitioner_id, partner_id: e.target.value })
        await this.props.practitioners({ practitioner_id, partner_id: e.target.value })
      } else {
        delete condition.partner_id
        await this.props.subPartners({ practitioner_id })
        await this.props.practitioners({ practitioner_id })
      }
    }
    if (type == 'kit') {
      if (e.target.value != 'select') {
        let kit_status = e.target.value;

        condition.kit_status = e.target.value;
        if (RESULT_TYPE_KIT_STATUS.includes(kit_status)) {
          let resultType = e.target.value.split('_').pop();
          condition.kit_status = kit_status.replace(/_(EPI|DNA)$/, '');
          condition.result_type = resultType
          console.log("condition for resultType", condition, resultType)
        } else
          delete condition.result_type
      } else {
        delete condition.kit_status
        delete condition.result_type
      }
    }
    this.setState({ filterCondition: condition })
    this.props.UserListByFiltration(condition)

  }

  practitionerSelectHandler(e) {
    let pid = e.target.value

  }

  async handleSubmit(user) {
    return await this.props.createAPI(user)
  }
  async addUserHandler() {
    this.setState({
      show1: true,
      partnerList: this.props.partnerList,
      practitionerList: this.props.practitionerList,
      subPartnerList: this.props.subPartnerList
    })
  }
  handleClose1 = () => {
    this.setState({
      show1: false,
      userDetails: {
        fname: '',
        lname: '',
        email: '',
        phnum: '',
        dob: '',
        kitId: '',
        partner: '',
        selected_dep: '',
        selected_clinic: '',
        practitioner: '',
        terms_condition: false,
        research: false,
        newsletter: false
      }
    })
    this.props.closeUserHandler()
  }

  handleClose = () => {
    this.props.clearAlert();
  }


  render() {
    const { t } = this.props;
    const { show1, filter_type, partnerList, subPartnerList, practitionerList } = this.props
    const { userDetails, kitStatusList } = this.state
    return (
      <>
        <div className="addBtn-home">
          <button
            className="addBtn"
            style={{ cursor: this.props.loader ? "no-drop" : "pointer", }}
            onClick={() => { if (!this.props.loader) { this.props.addUserHandler(); } }}
            disabled={this.props.loader}
          >
            <i class="bi bi-person-plus-fill"></i> {t('buttons.add_customer')}
          </button>
        </div>

        <div className="innerpagesFiltration">
          <div className="row justify-content-between align-items-end">
            <div className="col-12 col-sm-auto">
              <ul className="list-unstyled">
                {this.props.type == "admin" && (
                  <li key="partner">
                    <h6>{t('labels.partner')} : </h6>{" "}
                    <Form.Group>
                      <Form.Control
                        as="select"
                        name="admin"
                        onChange={this.selectHandler.bind(this)}
                      >
                        <option selected key="select" value="select">
                          --{t('labels.select_partner')}--
                        </option>
                        {partnerList &&
                          SortArray(partnerList).map((user) => (
                            <option key={user._id} value={user.partner_id}>
                              {user.first_name + " " + user.last_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>{" "}
                  </li>
                )}
                {(this.props.type == "admin" ||
                  this.props.type == "partner") &&
                  filter_type != "partner" && (
                    <li key="clinic">
                      <h6>{t('labels.subpartner')} : </h6>{" "}
                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="clinic"
                          onChange={this.selectHandler.bind(this)}
                        >
                          <option selected key="select" value="select">
                            --{t('labels.select_subpartner')}--
                          </option>
                          {subPartnerList &&
                            SortArray(subPartnerList).map((user) => (
                              <option key={user.id} value={user._id}>
                                {user.first_name + " " + user.last_name}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>{" "}
                    </li>
                  )}
                {this.props.type &&
                  this.props.type != "practitioner" &&
                  filter_type != "partner" &&
                  filter_type != "clinic" && (
                    <li key="practitioner">
                      <h6>{t('labels.practitioner')} : </h6>{" "}
                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="practitioner"
                          onChange={this.selectHandler.bind(this)}
                        >
                          <option selected key="select" value="select">
                            --{t('labels.select_practitioner')}--
                          </option>
                          {practitionerList &&
                            SortArray(practitionerList).map((user) => (
                              <option key={user._id} value={user._id}>
                                {user.first_name + " " + user.last_name}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>{" "}
                    </li>
                  )}
                <li key="kit-status">
                  <h6>{t('labels.kit_status')}:</h6>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="kit"
                      onChange={this.selectHandler.bind(this)}
                    >
                      <option selected key="select" value="select">
                        --{t('labels.select_kit_status')}--
                      </option>
                      {kitStatusList &&
                        kitStatusList.map((st, idx) => (
                          <option key={idx} value={st.value}>
                            {st.label}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {show1 && (
          <EditUser
            show={show1}
            UserDetails={userDetails}
            title={t('title.create_customer')}
            type="create"
            closeModal={this.handleClose1}
            updateAPI={this.handleSubmit.bind(this)}
            showAlert={this.props.showAlert}
            clearAlert={this.handleClose}
            submitted={this.props.submitted}
            filter_type={this.props.filter_type}
            partnerList={partnerList}
            subPartnerList={subPartnerList}
            practitioner_id={this.props.practitioner_id}
            practitioner_type={this.props.type}
            practitionerDetails={this.props.practitionerDetails}
            practitionerList={this.props.practitionerList}
            access={this.props.access}
          ></EditUser>
        )}
      </>
    );
  }
}


function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
};

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(PageFiltration);

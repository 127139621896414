import React, { useEffect } from "react";
import moment from "moment";
import "moment/locale/tr";
import "moment/locale/ro";
import { useTranslation } from "react-i18next";

const OverviewRecentDetails = ({ icon, details }) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        // Update moment locale when i18n language changes
        moment.locale(i18n.language); // Automatically syncs moment with the current language
    }, [i18n.language]); // Re-run when language changes

    return (
        <>
            <div className="overviewDetails">
                <h5>{details.msg}</h5>

                <ul className="list-unstyled">
                    {details.last3Date &&
                        details.last3Date.length > 0 &&
                        details.last3Date.map((date, i) => {
                            // Validate date before formatting
                            const isValidDate = moment(date, moment.ISO_8601, true).isValid();
                            return isValidDate ? (
                                <li key={i}>
                                    <img alt="icon" src={icon} className="filterit"></img>
                                    {moment(date).locale(i18n.language || "en").format('dddd')} - <span>{details.last3list[i]}</span>
                                </li>
                            ) : null;
                        })}
                </ul>
            </div>
        </>
    );
};

export default OverviewRecentDetails;

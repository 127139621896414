import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Table, Modal, Button, Spinner, Form } from "react-bootstrap";
import { apiService } from "../../services/api_service";
import { alertActions } from "../../actions/alert-action";
import { connect } from "react-redux";
import EditUser from "../../components/Modal/EditUser";

import {
  BsPencilSquare,
  BsFillTrashFill,
  BsPlusSquareFill,
} from "react-icons/bs";
import { MdHistory } from "react-icons/md";
import { FaSignInAlt, FaTag } from "react-icons/fa";

import { store } from "../../helpers";
import { regexKit, languageList, subscriptionOptions } from "../../constants/general-constants";

let ignore_users = [
  "60c30bb3eb37ed68d0730aa7",
  "NjE0MGM5MmY5ZDM1NDVkNGQ1NzA2ZjNl",
  "NjE3ZmQ1NGJmOTA4OGMwMDA5ZjI2N2Fm",
];

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practitioner_id: "",
      show: false,
      show1: false,
      kit_id: "",
      trackingHistory: [],
      history_width: "0%",
      request_status: this.props.t('customers_table.request_access'),
      open: true,
      loading: false,
      childLoader: false,
      show2: false,
      edit1: false,
      edit_user: {},
      showAlert: false,
      submitted: false,
      confirm_delete: false,
      released_user_id: {},
      release_dna: false,
      release_epi: false,
      release_dnaraw: false,
      release_blood: false,
      release_dna12: false,
      // release_dna12_actual: false,
      // release_dna12_genetic: false,
      check_error: false,
      check_user_error: false,
      check_lang_error: false,
      release_loading: false,
      confirm_permission: false,
      extra: 0,
      user: [],
      departments: [],
      clinics: [],
      practitioner_email: false,
      user_mail: true,
      report_language: "en",
      confirm_app_access: false,
      appAccessPartners: [],
      add_child_kit: false,
      child_kit: "",
      kitErrorMsg: "",
      error: { child_kit: false },
      relation: [],
      kit_type: "genetic",
      subscriptionLoader: false,
      cancelsubscriptionLoader: false,
      subscription_button: this.props.t('buttons.add_subscription'),
      add_subscription: false,
      activesubscription: false,
      subscriptiondata: {},
      dna12subscriptiondata: {},
      subscription_type: "",
      type: "add",
      sub_type: "add",
      subscribed: false,
      startDate: "",
      endDate: "",
      dna12startDate: "",
      dna12endDate: "",
      subscription: subscriptionOptions(),
      subscription_name: [],
      subscription_text: "",
      subOpen: true,
    };
    this.handleEdit1 = this.handleEdit1.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.clearAlert = this.clearAlert.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.ReleasePDFs = this.ReleasePDFs.bind(this);
    this.closeAccess = this.closeAccess.bind(this);
    this.requestAccess = this.requestAccess.bind(this);
    this.confirmAccess = this.confirmAccess.bind(this);
    // this.handleResendEmail = this.handleResendEmail.bind(this)
    this.enableAppAccess = this.enableAppAccess.bind(this);
    this.disableAppAccess = this.disableAppAccess.bind(this);
    this.closeAppAccess = this.closeAppAccess.bind(this);
    this.requestAppAccess = this.requestAppAccess.bind(this);
    this.confirmAppAccess = this.confirmAppAccess.bind(this);
    this.childKitModal = this.childKitModal.bind(this);
    this.switchKitType = this.switchKitType.bind(this);
    this.subscriptionModal = this.subscriptionModal.bind(this);
    this.addSubscription = this.addSubscription.bind(this);
    this.checkSubscription = this.checkSubscription.bind(this);
    this.handleLanguageSelect = this.handleLanguageSelect.bind(this);
  }

  switchKitType = (e, kit_type) => {
    e.persist();
    this.setState({ kit_type, child_kit: "" });

    // if (this.state.child_kit.trim().length) {
    //   setTimeout(() => {
    //     this.kitIdValidateHandler(this.state.child_kit);
    //   }, 100);
    // }
  }

  switchSubscriptionType = (e, subscription_type) => {
    e.persist();
    this.setState({ subscription_type });
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps?.user?._id && nextProps?.user?._id !== this?.state?._id) {
      const practitioner_id = btoa(nextProps.user._id);
      this.setState({ practitioner_id, ...nextProps.user });
    }
  }

  async componentDidMount() {
    let appPartners = await apiService.getAppAllowedPartners(-1);
    let appAccessPartners = appPartners.map((p) => p.partner_id);
    this.setState({ appAccessPartners });
  }

  handleClose = () => this.setState({ show: false });
  historyHandler = async (user) => {
    let kit_history = await apiService.kitHistory(
      user.parentHistory ? user.kit_id : user.target?.value
    );

    if (!kit_history) return;

    if (user.parentHistory) {
      let userdetails = this.props.userDetails.find(
        (usr) => usr._id === user._id
      );
      this.setState({
        kit_id: user.kit_id,
        relation: userdetails ? userdetails.relation : [],
      });
    }
    if (kit_history.length == 0) {
      this.setState({ show: false, show2: true });
      return;
    }
    let extra = 0;
    if (kit_history.length < 4) {
      extra = 4 - kit_history.length;
    }
    let historyCount = 25;
    let i = 0,
      length;
    for (let each of kit_history) {
      length = i * historyCount + "%";
      each.width = length;
      // if (kit_history[i].status === 'RECEIVED')
      //   kit_history[i].status = 'RECEIVED AT LAB'
      if (each.result_type) {
        let result_type = kit_history[i].result_type == 'EPIGENETICS' ? 'EPI' : kit_history[i].result_type
        kit_history[i].status += `_${result_type}`
      }
      kit_history[i].status = this.props.t(`kit_tracking.${kit_history[i].status}`)
      i++;
    }
    this.setState({
      show: true,
      trackingHistory: kit_history,
      history_width: length,
      extra,
    });
  };
  handleOpen = () => {
    let error = false;
    if (
      !this.state.release_dna &&
      !this.state.release_epi &&
      !this.state.release_dnaraw &&
      !this.state.release_blood &&
      !this.state.release_blood &&
      !this.state.release_dna12
      // !this.state.release_dna12_actual &&
      // !this.state.release_dna12_genetic
    ) {
      this.setState({ check_error: true });
      error = true;
    }
    if (!this.state.user_mail && !this.state.practitioner_email) {
      this.setState({ check_user_error: true });
      error = true;
    }
    if (this.state.report_language == 'select') {
      error = true;
      this.setState({ check_lang_error: true });
    }
    if (!error) {
      this.setState({
        open: !this.state.open,
        check_error: false,
        check_user_error: false,
        check_lang_error: false,
      });
    }
  };

  handleClose1 = () => {
    this.setState({
      show1: false,
      open: true,
      check_error: false,
      check_user_error: false,
      check_lang_error: false,
      release_dna: false,
      release_epi: false,
      release_dnaraw: false,
      release_blood: false,
      release_dna12: false,
      user_mail: true,
      practitioner_email: false,
      report_language: "en",
      // release_dna12_actual: false,
      // release_dna12_genetic: false,
    });
  };
  handleChange = (event) => {
    console.log("handleChange", event.target.value);
    const child_kit = event.target.value?.trim()?.toUpperCase();
    this.setState({ child_kit });
    this.kitIdValidateHandler(child_kit);
  };
  handleShow1 = (user) => {
    this.setState({ show1: true, released_user_id: user });
  };
  handleClose2 = () => this.setState({ show2: false });
  handleShow2 = () => this.setState({ show2: true });
  handleEdit1 = async (user) => {
    let departments = [];
    let clinics = [];
    if (user.user_type === "Partner" && this.props.type === "partner") {
      departments = await apiService.departmentListAPI({
        partner_id: user.partner_id,
        practitioner_id: this.state.practitioner_id,
      });
      departments = departments[0] ? departments[0].dept : [];
    }
    if (user.user_type === "Partner" && this.props.type === "admin") {
      departments = await apiService.departmentListAPI({
        partner_id: user.partner_id,
        practitioner_id: this.state.practitioner_id,
      });
      departments = departments[0] ? departments[0].dept : [];
    }
    if (
      user.user_type === "Clinic" &&
      (this.props.type === "partner" || this.props.type === "admin")
    ) {
      clinics = await apiService.ClinicList({
        partner_id: user.partner_id,
      });
      // console.log(clinics,'clinic')
      clinics = clinics[0] ? clinics[0].users : [];
      departments = await apiService.departmentListAPI({
        practitioner_id: this.state.practitioner_id,
        partner_id: user.partner_id,
        clinic_id: user.clinic_id[0],
      });
      departments = departments[0] ? departments[0].dept : [];
    }
    if (user.user_type == "Clinic" && this.props.type == "clinic") {
      departments = await apiService.departmentListAPI({
        clinic_id: atob(this.state.practitioner_id),
        practitioner_id: this.state.practitioner_id,
        partner_id: user.partner_id,
      });
      departments = departments[0] ? departments[0].dept : [];
    }
    this.setState({ edit1: true, edit_user: user, departments, clinics });
  };
  childKitModal = (user) => {
    this.setState({
      kit_type: "genetic",
      add_child_kit: true,
      user,
    });
  };

  getKitId = (user) => {
    let kit_id_str = user.kit_id;

    if (user.kit_id) {
      let totalKits = user?.relation?.[0]?.children?.length || 0;
      if (totalKits > 0) {
        kit_id_str += " & " + totalKits + " more";
      }
    }

    return kit_id_str;
  };

  getServiceType = (user) => {
    let serviceType = user?.result_type || "";
    if (this.props?.access?.blood_test && user.blood_kit_id)
      serviceType = user.result_type ? `${serviceType} + Blood` : "Blood";

    return serviceType;
  };

  showReleaseButton = (user) => {
    if (!user.kit_id && !user.blood_kit_id) return true;
    return false;
    // return !ignore_users.includes(this.state.practitioner_id) ||
    //   user.kit_id == ""
    //   ? !user.user_type || (user.kit_id == "" && user.blood_kit_id == "")
    //     ? true
    //     : user.practitioner_id &&
    //       user.practitioner_id.includes(
    //         this.state.practitioner_id
    //       )
    //       ? true
    //       : null
    //   : null
  };
  releaseButtonColor = (user) => {
    if (!user.kit_id && !user.blood_kit_id) return "grey";
    return null;
    // return !ignore_users.includes(this.state.practitioner_id) ||
    //   user.kit_id == ""
    //   ? !user.user_type || (user.kit_id == "" && user.blood_kit_id == "")
    //     ? "grey"
    //     : user.practitioner_id &&
    //       user.practitioner_id.includes(
    //         this.state.practitioner_id
    //       )
    //       ? "grey"
    //       : null
    //   : null
  };

  addChildKit = async (user) => {
    const { t } = this.props;
    console.log("API call to add ", this.state.child_kit, user);
    this.setState({ childLoader: true });
    const user_id = Buffer.from(String(user._id)).toString("base64");

    let updatedChild =
      this.state.kit_type === "genetic"
        ? await apiService.AddChildKitToExistingUser({
          user_id,
          kit_id: this.state.child_kit,
        })
        : await await apiService.assignBloodKitToUser({
          user_id,
          kit_id: this.state.child_kit,
        });

    if (updatedChild.status === 200) {
      this.props.getAllUsers();
      this.setState({
        add_child_kit: false,
        child_kit: "",
        kitErrorMsg: "",
        childLoader: false,
      });
    } else {
      this.setState({
        error: { ...this.state.error, child_kit: true },
        kitErrorMsg: updatedChild?.message || t('validations.kit_id'),
        childLoader: false,
      });
    }
  };
  async kitIdValidateHandler(child_kit) {
    const { t } = this.props;
    if (!child_kit || !child_kit.match(regexKit)) {
      this.setState({
        error: { ...this.state.error, child_kit: true },
        kitErrorMsg: t('validations.kit_id'),
      });
      return;
    }
    let res =
      this.state.kit_type === "genetic"
        ? await apiService.KitIdValidator(child_kit)
        : await apiService.CheckAssignBloodKitStatus({ kit_id: child_kit });

    this.setState({
      error: { ...this.state.error, child_kit: !res || res?.status !== 200 },
      kitErrorMsg:
        res.status !== 200 ? res?.message || this.props.t('validations.invalid_child_kit') : "",
    });
  }

  closeChildKit = () => {
    this.setState({ add_child_kit: false, child_kit: "", kitErrorMsg: "" });
  };
  closeAppSubscription = () => {
    this.setState({ activesubscription: false });
  };
  closeSubscription = () => {
    const { t } = this.props;
    this.setState({
      activesubscription: false,
      add_subscription: false,
      subscriptiondata: "",
      subscription_type: "",
      subscription_name: "",
      subscription_text: "",
      sub_type: "add",
      subscribed: false,
      subscription_button: t('buttons.add_subscription'),
      subOpen: true,
    });
  };
  closeEditModal = () => {
    this.setState({ edit1: false });
  };
  handleDelete = (user) => {
    this.setState({ confirm_delete: true, edit_user: user });
  };
  closeDelete = () => {
    this.setState({ confirm_delete: false, edit_user: "" });
  };
  confirmDelete = async () => {
    this.setState({ submitted: true });
    let body = {
      user_id: Buffer.from(String(this.state.edit_user._id)).toString("base64"),
    };
    let deleteUser = await apiService.DeleteUser(body);
    this.props.getAllUsers();
    // this.props.reloadUsers()
    this.setState({ confirm_delete: false, submitted: false });
    this.props.updateUserDetails(this.state.edit_user, "delete");
  };
  updateUser = async (body) => {
    // console.log('updaaaaaaaaaaaa')
    this.setState({ submitted: true });
    body.user_id = Buffer.from(String(this.state.edit_user._id)).toString(
      "base64"
    );
    // body.practitioner_id = this.state.practitioner_id

    let updatedData = await apiService.UpdateUserDetails(body);
    this.props.getAllUsers();
    if (!updatedData) {
      this.setState({ submitted: false, showAlert: true });
    } else {
      this.setState({
        showAlert: false,
        edit1: false,
        submitted: false,
        show2: false,
      });
      this.props.updateUserDetails(updatedData, "update");
    }
  };
  requestAccess(user) {
    this.setState({ user });
    this.closeAccess();
  }
  closeAccess() {
    this.setState({ confirm_permission: !this.state.confirm_permission });
  }
  confirmAccess() {
    this.props.updateUser(this.state.user);
    this.closeAccess();
  }

  requestAppAccess(user) {
    this.setState({ user });
    this.closeAppAccess();
  }
  closeAppAccess() {
    this.setState({ confirm_app_access: !this.state.confirm_app_access });
  }
  confirmAppAccess() {
    this.enableAppAccess(this.state.user);
    this.closeAppAccess();
  }
  disableAppAccess(user) {
    const { appAccessPartners } = this.state;
    if (!appAccessPartners.includes(user.partner_id)) {
      return true;
    }
    if (!user.user_type || user.mobile_access) {
      return true;
    }
    return false;
  }
  async ReleasePDFs() {
    try {
      this.setState({ release_loading: true });
      let condition = {
        user_id: Buffer.from(String(this.state.released_user_id._id)).toString("base64"),
        practitioner_id: this.state.practitioner_id,
      };

      if (this.state.release_dna && this.state.release_epi) {
        condition.type = "DNA_EPI";
      } else if (this.state.release_dna) {
        condition.type = "DNA";
      } else if (this.state.release_epi) {
        condition.type = "EPI";
      }

      // let promises = {};
      let promises = [];
      if (condition.type || this.state.release_dnaraw) {
        condition = {
          ...condition,
          release_dnaraw: this.state.release_dnaraw,
          practitioner_mail: this.state.practitioner_email,
          user_mail: this.state.user_mail,
          report_language: this.state.report_language
        };
        // promises["releasePDFs"] = apiService.releasePDFs(condition);
        promises.push(apiService.releasePDFs(condition));
      }

      if (this.state.release_blood) {
        const reqBody = {
          practitioner_mail: this.state.practitioner_email,
          user_mail: this.state.user_mail,
          user_id: Buffer.from(
            String(this.state.released_user_id._id)
          ).toString("base64"),
          practitioner_id: this.state.practitioner_id,
        };
        // promises["releaseBloodPdf"] = apiService.releaseBloodPdf(reqBody);
        promises.push(apiService.releaseBloodPdf(reqBody))
      }

      // const { release_dna12_genetic = false, release_dna12_actual = false } =
      //   this.state;
      const { release_dna12 = false } = this.state;

      // if (release_dna12_genetic || release_dna12_actual) {
      if (release_dna12) {
        const reqBody = {
          user_id: Buffer.from(
            String(this.state.released_user_id._id)
          ).toString("base64"),
          practitioner_mail: this.state.practitioner_email,
          user_mail: this.state.user_mail,
          report_language: this.state.report_language
          // release_dna12_genetic,
          // release_dna12_actual,
        };
        // promises["releaseDNA12ReportPDF"] = apiService.releaseDNA12ReportPDF(reqBody);
        promises.push(apiService.releaseDNA12ReportPDF(reqBody))
      }

      setTimeout(() => {
        this.setState({ release_loading: false }, () => this.handleClose1());
        store.dispatch(
          alertActions.success(
            this.props.t('messages.request_processed_shortly')
          )
        );
      }, 1500);

      await Promise.all(promises)
      // const resPromise = await Promise.all(Object.values(promises));

      // const responses = await Promise.all(
      //   Object.entries(promises).map(async ([key, promise]) => {
      //     try {
      //       const response = await promise;
      //       return { key, response };
      //     } catch (error) {
      //       return { key, error };
      //     }
      //   })
      // );
      // this.setState({ release_loading: false }, () => this.handleClose1());

      // let successReponse = [];
      // let errorReponse = [];

      // console.log(responses, "responses")
      // for (let item of responses) {
      //   const reportName = {
      //     releasePDFs: "Genetic",
      //     releaseBloodPdf: "Blood",
      //     releaseDNA12ReportPDF: "DNA12",
      //   }[item.key];

      //   if (typeof item.response === "object") {
      //     console.log("coming here objecttttt", item.response)
      //     if (!item.response.error) {
      //       successReponse.push(reportName);
      //     } else {
      //       errorReponse.push(reportName);
      //     }
      //   }
      // }

      // function getMsg() {
      //   let joinWithSeprator = "";
      //   if (successReponse.length === 1) {
      //     joinWithSeprator = successReponse[0];
      //   } else if (successReponse.length > 1) {
      //     joinWithSeprator =
      //       successReponse.slice(0, -1).join(", ") +
      //       " & " +
      //       successReponse[successReponse.length - 1];
      //   }
      //   return joinWithSeprator;
      // }

      // setTimeout(() => {
      //   let alerts = [];
      //   if (successReponse.length > 0) {
      //     const resMsg = getMsg(successReponse);
      //     console.log(resMsg);
      //     if (resMsg) {
      //       let message = `We have received your request for ${resMsg}. It will be processed shortly`;
      //       alerts.push({ type: "alert-success", message });
      //     }
      //   }

      //   if (errorReponse.length > 0) {
      //     const resMsg = getMsg(errorReponse);
      //     console.log(resMsg);
      //     if (resMsg) {
      //       let message = `${resMsg} Report generation failed. Please contact administrator.`;
      //       alerts.push({ type: "alert-danger", message });
      //     }
      //   }
      //   store.dispatch(alertActions.bulk_alert(alerts));
      // }, 1000);
    } catch (error) {
      console.log("Error in handleSubmit", error);
      this.setState({ release_loading: false });
      setTimeout(() => {
        store.dispatch(
          alertActions.success(this.props.t('messages.error.report_error'))
        );
      }, 1500);
    }
  }

  clearAlert() {
    this.setState({ showAlert: false });
  }

  componentDidUpdate() {
    if (this.state.showAlert === true) {
      setTimeout(() => {
        this.clearAlert();
      }, 3000);
    }
  }

  // handleResendEmail = async(user) =>{
  //     console.log("list", user)
  //     user.practitioner_id = this.state.practitioner_id
  //     let resendEmail = await apiService.UserResendEmail(user)
  //     console.log("resend email", resendEmail)
  // }

  enableAppAccess = async (user) => {
    console.log("user id", user._id, this.state.practitioner_id);
    let appAccess = await apiService.enableAppAccess({
      user_id: Buffer.from(String(user._id)).toString("base64"),
      //practitioner_id: this.state.practitioner_id,
    });
    console.log("appAccess", appAccess);
    setTimeout(() => {
      store.dispatch(
        alertActions.success(`Password reset email sent to user ${user.email}`)
      );
    }, 1500);
  };

  subscriptionModal = async (user) => {
    let subscriptiondata = await apiService.getSubcriptionStatus({
      user_id: btoa(user._id)
    });
    let dna12subscriptiondata = await apiService.getDna12Subscription({
      user_id: btoa(user._id)
    })
    this.setState({
      subscriptiondata: subscriptiondata,
      dna12subscriptiondata: dna12subscriptiondata,
      user
    })
    if (subscriptiondata.appsubscribed == true || dna12subscriptiondata.appSubscribed == true) {
      let newState = { add_subscription: true }
      if (subscriptiondata.appsubscribed == true && dna12subscriptiondata.appSubscribed == true) {
        newState = {
          ...newState,
          activesubscription: true,
          add_subscription: false
        }
      }

      if (subscriptiondata.appsubscribed)
        newState.endDate = moment(subscriptiondata.appsubscriptions.endDate).format("DD-MM-YYYY")

      if (dna12subscriptiondata.appSubscribed)
        newState.dna12endDate = moment(dna12subscriptiondata.appSubscription.endDate).format("DD-MM-YYYY")

      console.log("newState", newState)
      this.setState(newState)

    } else if (subscriptiondata.portalsubscribed == true || dna12subscriptiondata.portalSubscribed == true) {
      this.setState({
        activesubscription: false,
        add_subscription: true,
        user,
        startDate: moment(subscriptiondata?.portalsubscriptions?.startDate).format("DD-MM-YYYY"),
        endDate: moment(subscriptiondata?.portalsubscriptions?.endDate).format("DD-MM-YYYY"),
        dna12startDate: moment(dna12subscriptiondata?.portalSubscription?.startDate).format("DD-MM-YYYY"),
        dna12endDate: moment(dna12subscriptiondata?.portalSubscription?.endDate).format("DD-MM-YYYY")
      })
    } else {
      this.setState({
        add_subscription: true,
        user
      });
    }
  };

  async subscriptionTypeChecked(user) {
    const { t } = this.props;
    this.setState({ subscription_button: t('buttons.add_subscription'), subOpen: true });
    if (user.target.value == "face_brain") {
      this.setState({
        subscription_name: user.target.value,
        subscription_text: "Face and Brain",
        subscribed: true,
        sub_type: this.state.subscriptiondata.portalsubscriptions ? "edit" : this.state.sub_type,
        subscription_type: this.state.subscriptiondata.portalsubscriptions ? this.state.subscriptiondata.portalsubscriptions.subscription_type : "",
        subscription_button: this.state.subscriptiondata.portalsubscriptions ? t('buttons.edit_subscription') : this.state.subscription_button
      })
    } else if (user.target.value == "dna12") {
      this.setState({
        subscription_name: user.target.value,
        subscription_text: "DNA12",
        subscribed: true,
        sub_type: this.state.dna12subscriptiondata.portalSubscribed ? "edit" : this.state.sub_type,
        subscription_type: this.state.dna12subscriptiondata.portalSubscribed ? this.state.dna12subscriptiondata.portalSubscription.subscription_type : "",
        subscription_button: this.state.dna12subscriptiondata.portalSubscribed ? t('buttons.edit_subscription') : t('buttons.add_subscription')
      })
    } else {
      this.setState({
        sub_type: "add",
        subscribed: false,
        subscription_type: user.target.value,
        subscription_button: t('buttons.add_subscription'),
        subscription_name: "",
      });
    }
  }

  async cancelSubscription(user) {
    const { t } = this.props;
    this.setState({
      cancelsubscriptionLoader: true
    });
    let body = {
      user_id: btoa(user._id),
      last_modified_by: btoa(this.state._id),
      subscription_name: this.state.subscription_name,
      subscription_type: this.state.subscription_type,
      status: "active",
      type: "cancel"
    }
    let createSubscription = await apiService.createSubscription(body);
    console.log("createSubscription", createSubscription)
    if (createSubscription.statusCode === 200) {
      await this.props.getAllUsers();
      this.setState({
        add_subscription: false,
        subscription_name: "",
        subscription_text: "",
        subscription_type: "",
        subscription_button: t('buttons.add_subscription'),
        cancelsubscriptionLoader: false,
      });
    } else {
      this.setState({
        cancelsubscriptionLoader: false,
      });
    }
  }

  async addSubscription(user) {
    const { t } = this.props;
    this.setState({ subscriptionLoader: true });
    let body = {
      user_id: btoa(user._id),
      last_modified_by: btoa(this.state._id),
      subscription_name: this.state.subscription_name,
      subscription_type: this.state.subscription_type,
      status: "active",
      type: this.state.sub_type
    }
    let createSubscription = await apiService.createSubscription(body);
    if (createSubscription.statusCode === 200) {
      await this.props.getAllUsers();
      this.setState({
        subscription_name: "",
        subscription_type: "",
        subscription_button: t('buttons.add_subscription'),
        subscriptionLoader: false,
        add_subscription: false,
        subOpen: true
      });
    } else {
      this.setState({
        subscriptionLoader: false,
        subOpen: true
      });
    }
  }

  async checkSubscription(user) {
    const { subscription_name } = this.state;

    if (subscription_name == "dna12") {
      this.setState({ subscriptionLoader: true });
      let body = {
        user_id: btoa(user._id)
      }
      let checkSubs = await apiService.checkSubscription(body);
      console.log("checkSubs", checkSubs);
      if (checkSubs.statusCode == 400)
        this.setState({ subMessage: checkSubs.message, subInfoModalShow: true })
    }
    this.setState({
      subOpen: false,
      subscriptionLoader: false
    })

  }

  handleLanguageSelect(e) {
    let report_language = e.target.value, check_lang_error = false
    if (report_language == 'select') {
      check_lang_error = true
    }
    this.setState({ report_language, check_lang_error })
  }
  render() {
    let {
      show, show1, kit_id, trackingHistory, history_width, release_loading, extra,
      request_status, open, show2, edit1, check_error, check_user_error, check_lang_error,
      edit_user, showAlert, submitted, confirm_delete, released_user_id, confirm_permission,
      user, confirm_app_access, add_child_kit, child_kit, kitErrorMsg, childLoader, relation,
      add_subscription, activesubscription, subscribed, subscriptiondata, dna12subscriptiondata,
      subscription_button, startDate, endDate, dna12startDate, dna12endDate, subscriptionLoader,
      subOpen, subInfoModalShow, subMessage, report_language
    } = this.state;

    const { blood_test_access, t } = this.props;

    const dna12Access = this.props?.user?.access_controls?.dna12;
    return (
      <div className="cmnTable">
        <Table responsive striped>
          <thead>
            <tr>
              <th>{t('customers_table.name')}</th>
              <th>{t('customers_table.email')}</th>
              <th>{t('customers_table.phone')}</th>
              <th>{t('customers_table.gender')}</th>
              {this.props?.access?.blood_test ?
                <th>{t('customers_table.blood_kit_id')}</th>
                : null}
              {this.props?.access?.blood_test ? (
                <th>{t('customers_table.genetic_kit_id')}</th>
              ) : (
                <th>{t('customers_table.kit_id')}</th>
              )}
              <th>{t('customers_table.service')}</th>
              <th>{t('customers_table.customer_type')}</th>
              <th>{t('customers_table.reports')}</th>
              {this.props.practitionerDetails.request_access !== true && (
                <th>{t('customers_table.permission')}</th>
              )}
              <th>{t('customers_table.practitioner_report')}</th>

              <th style={{ textAlign: "center" }}>{t('customers_table.action')}</th>
            </tr>
          </thead>
          <tbody>
            {!this.props.loader && this.props.userDetails.length <= 0 ? (
              <tr style={{ textAlign: "center" }}>
                <td colSpan={10}>
                  <h5>{t('no_customers')}</h5>
                </td>
              </tr>
            ) : null}
            {!this.props.loader &&
              this.props.userDetails.map((user) => (
                <tr key={user._id}>
                  <td>{user.first_name + " " + user.last_name}</td>
                  {/* <td>{user.last_name}</td> */}
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>
                    {user.gender === 'male' ? 'M' : user.gender === 'female' ? 'F' : '-'}
                  </td>
                  {this.props?.access?.blood_test ? (
                    <td>{`${user?.blood_kit_id ?? ""} 
                  ${user?.bloodKits?.length
                        ? `& ${user?.bloodKits?.length} ${t('customers_table.more')}`
                        : ""
                      }`}</td>
                  ) : null}
                  <td>{this.getKitId(user)}</td>
                  <td>{this.getServiceType(user)}</td>
                  <td>
                    {user.user_type ? t('customers_table.helix_customer') : t('customers_table.mobile_customer')}
                  </td>
                  { }
                  <td>
                    {/* {console.log("condition", user, user.kit_id, !user.user_type || user.kit_id =='', !ignore_users.includes(this.state.practitioner_id))} */}
                    <button
                      className="releasingBtn"
                      disabled={this.showReleaseButton(user)}
                      style={{ backgroundColor: this.releaseButtonColor(user) }}
                      onClick={() => this.handleShow1(user)}
                    >
                      <img src="images/releaseicon.svg"></img>
                      {t('customers_table.reports')}
                    </button>
                  </td>

                  {this.props.practitionerDetails.request_access !== true &&
                    user.is_accepted == true && (
                      <td>
                        <button
                          disabled
                          id={user._id}
                          className="requestBtn accepted"
                          style={{ backgroundColor: "#04AA6D", color: "white" }}
                        >
                          {t('customers_table.request_accepted')}
                        </button>
                      </td>
                    )}
                  {this.props.practitionerDetails.request_access !== true &&
                    user.is_accepted == false && (
                      <td>
                        <button
                          // onClick={()=>this.props.updateUser(user)}
                          onClick={() => this.requestAccess(user)}
                          id={user._id}
                          className="requestBtn pending"
                          disabled={user.loading}
                        >
                          {user.loading && (
                            <Spinner
                              className="spinnerClass"
                              animation="border"
                              variant="info"
                              size="sm"
                              style={{ float: "right", marginLeft: "5px" }}
                            />
                          )}
                          {user.report_access_status
                            ? user.report_access_status
                            : t('customers_table.request_pending')}
                        </button>
                      </td>
                    )}
                  {this.props.practitionerDetails.request_access !== true &&
                    !user.report_access_status && (
                      <td>
                        <button
                          // onClick={()=>this.props.updateUser(user)}
                          onClick={() => this.requestAccess(user)}
                          id={user._id}
                          className="requestBtn"
                          disabled={user.loading}
                        >
                          {user.loading && (
                            <Spinner
                              className="spinnerClass"
                              animation="border"
                              variant="info"
                              size="sm"
                              style={{ float: "right", marginLeft: "5px" }}
                            />
                          )}
                          {user.report_access_status
                            ? user.report_access_status
                            : t('customers_table.request_access')}
                        </button>
                      </td>
                    )}
                  <td style={{ textAlign: "center" }}>
                    {user.practitionerreport ? t('buttons.yes') : "-"}
                  </td>
                  <td>
                    <div
                      className="action-icons"
                      style={{ display: "inline-flex" }}
                    >
                      <button
                        onClick={() =>
                          this.historyHandler({ ...user, parentHistory: true })
                        }
                        id={user.kit_id}
                        className="tableviewLink"
                        title={t('title.show_history')}
                        disabled={user.kit_id == "" ? true : null}
                      >
                        <MdHistory
                          size={20}
                          color={user.kit_id == "" ? "grey" : ""}
                        ></MdHistory>
                      </button>
                      <button
                        onClick={() => this.childKitModal(user)}
                        id={user.kit_id}
                        className="tableviewLink"
                        title={t('title.add_child_kit')}
                        disabled={!user.user_type ? true : null}
                      >
                        <BsPlusSquareFill
                          size={15}
                          color={!user.user_type ? "grey" : ""}
                        ></BsPlusSquareFill>
                      </button>
                      {this.props?.access?.subscription && (
                        <button
                          onClick={() => this.subscriptionModal(user)}
                          id={user.kit_id}
                          className="tableviewLink"
                          title={t('title.manage_subscription')}
                        >
                          <FaTag size={15}></FaTag>
                        </button>
                      )}
                      <button
                        onClick={() => this.handleEdit1(user)}
                        id={user.kit_id}
                        className="tableviewLink"
                        title={t('title.edit_customer')}
                        disabled={!user.user_type ? true : null}
                      >
                        <BsPencilSquare
                          size={15}
                          color={!user.user_type ? "grey" : ""}
                        ></BsPencilSquare>
                      </button>
                      <button
                        onClick={() => this.handleDelete(user)}
                        id={user.kit_id}
                        className="tableviewLink"
                        title={t('title.delete_customer')}
                        disabled={!user.user_type ? true : null}
                      >
                        <BsFillTrashFill
                          size={15}
                          color={!user.user_type ? "grey" : "red"}
                        ></BsFillTrashFill>
                      </button>
                      <button
                        onClick={() => this.requestAppAccess(user)}
                        id={user._id}
                        className="tableviewLink"
                        title={t('title.enable_app_access')}
                        disabled={this.disableAppAccess(user)}
                      >
                        <FaSignInAlt
                          size={15}
                          color={this.disableAppAccess(user) ? "grey" : ""}
                        ></FaSignInAlt>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {this.props.loader && (
          <Spinner
            className="spinnerClass"
            animation="border"
            variant="info"
            style={{
              marginLeft: "50%",
              padding: "20px",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
        )}

        <Modal
          show={show2}
          onHide={this.handleClose2}
          centered
          className="trackingModal"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Kit tracking History: {kit_id}</Modal.Title>
          </Modal.Header>
          <Modal.Body> No history found</Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={this.handleClose}
          centered
          className="trackingModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="tracking-history">
              {t('title.kit_tracking')}:
              {relation.length > 0 && relation[0].children ? (
                <Form.Group className="tracking-history-form">
                  <Form.Control
                    as="select"
                    name="tracking"
                    style={{
                      height: "31px",
                      marginTop: "-6px",
                      borderRadius: "15px",
                    }}
                    onChange={this.historyHandler.bind(this)}
                  >
                    <option selected key="select" value={kit_id}>
                      {kit_id}
                    </option>
                    {relation.length > 0
                      ? relation[0].children.map((child, index) => (
                        <option key={child.kit_id} value={child.kit_id}>
                          {child.kit_id}
                        </option>
                      ))
                      : null}
                  </Form.Control>
                </Form.Group>
              ) : (
                " " + kit_id
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* add class name "st-dispatched /st-received / st-resultReady" for changing the progress*/}

            <div className="tracking-wrp st-resultReady">
              <ul className="list-unstyled" style={{ display: "flex" }}>
                {trackingHistory.map((kit, index) => (
                  <>
                    <div
                      key={index}
                      className="tracking-detail"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <li
                        className="status active"
                        style={{ left: kit.width, padding: 15 }}
                      >
                        {kit.status.replace(/_/g, " ")}
                      </li>
                      <li
                        className="active"
                        style={{ left: "18%", position: "relative" }}
                      >
                        <div style={{ display: "flex" }}>
                          <span
                            className="trackingNode"
                            style={{ left: kit.width }}
                          >
                            <img
                              style={{ margin: "8px" }}
                              width="10px"
                              src="images/statuschecked.svg"
                            ></img>
                          </span>
                          {index == trackingHistory.length - 1 ? (
                            extra != 0 && (
                              <div className="trackingbar">
                                <div
                                  className="trackingstatus"
                                  style={{
                                    width: "150px",
                                    border: "2px solid #c0c2c0",
                                    backgroundColor: "#ffff",
                                  }}
                                ></div>
                              </div>
                            )
                          ) : (
                            <div className="trackingbar">
                              <div
                                className="trackingstatus"
                                style={{ width: "150px" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </li>
                      <li
                        className="date active"
                        style={{ left: kit.width, width: "100%", padding: 15 }}
                      >
                        {moment(kit.date).format("DD-MM-YYYY")}
                      </li>
                    </div>
                  </>
                ))}
                {extra <= 4 &&
                  [...Array(extra).keys()].map((index) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <li className="active" style={{ padding: 17 }}>
                        {""}
                      </li>
                      <li
                        className=""
                        style={{
                          left: "18%",
                          position: "relative",
                          marginTop: "15px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <span
                            style={{
                              backgroundColor: "#ffff",
                              border: "2px solid #c0c2c0",
                              zIndex: 10,
                              borderRadius: "20px",
                            }}
                          >
                            <img
                              style={{ margin: "8px" }}
                              width="10px"
                              src="images/statuschecked.svg"
                            ></img>
                          </span>
                          {index == extra - 1 ? null : (
                            <div className="trackingbar">
                              <div
                                className="trackingstatus"
                                style={{
                                  width: "150px",
                                  border: "2px solid #c0c2c0",
                                  backgroundColor: "#ffff",
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </li>
                      <li
                        className="active"
                        style={{ width: "100%", padding: 15 }}
                      >
                        {""}
                      </li>
                    </div>
                  ))}
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show1}
          onHide={this.handleClose1}
          centered
          className="trackingModal releaseModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t('title.release_report')} : {released_user_id.kit_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Label>
                <b>{t('labels.select_result_type')}</b>
              </Form.Label>
              <br />
              {/* Result type start */}
              <Form.Group
                controlId="formBasicCheckbox"
                className="customCheckbox"
              >
                <div className="row">
                  <div className="col-md-6">
                    <input
                      checked={this.state.release_dna}
                      onClick={() => {
                        this.setState({ release_dna: !this.state.release_dna });
                      }}
                      type="checkbox"
                      label={t('release_dna_pdf')}
                    />
                    <Form.Label>{t('release_dna_pdf')}</Form.Label>
                    <br />
                    <input
                      checked={this.state.release_epi}
                      onClick={() => {
                        this.setState({ release_epi: !this.state.release_epi });
                      }}
                      type="checkbox"
                      label={t('release_epi_pdf')}
                    />
                    <Form.Label>{t('release_epi_pdf')}</Form.Label>
                    <br />

                    <input
                      checked={this.state.release_dnaraw}
                      onClick={() => {
                        this.setState({
                          release_dnaraw: !this.state.release_dnaraw,
                        });
                      }}
                      type="checkbox"
                      label={t('release_dna_raw_data')}
                    />
                    <Form.Label>{t('release_dna_raw_data')}</Form.Label>
                    <br />
                  </div>

                  <div className="col-md-6">
                    {blood_test_access && (
                      <>
                        <input
                          checked={this.state.release_blood}
                          onClick={() => {
                            this.setState({ release_blood: !this.state.release_blood });
                          }}
                          type="checkbox"
                          label={t('release_blood_pdf')}
                        />
                        <Form.Label>{t('release_blood_pdf')}</Form.Label>
                        <br />
                      </>
                    )}
                    {dna12Access &&
                      Object.keys(dna12Access).length > 0 &&
                      Object.values(dna12Access).some(Boolean) && (
                        // dna12Access
                        <>
                          <input
                            checked={this.state.release_dna12}
                            onClick={() => {
                              this.setState({ release_dna12: !this.state.release_dna12 });
                            }}
                            type="checkbox"
                            label={t('release_dna12_pdf')}
                          />
                          <Form.Label>{t('release_dna12_pdf')}</Form.Label>
                        </>
                      )}
                  </div>
                </div>
                <Form.Text className="text-muted">
                  {check_error ? t('validations.select_one_report') : null}
                </Form.Text>
              </Form.Group>
              {/* Result type end */}

              {/* Language select start */}
              <Form.Group
                controlId="formBasicCheckbox"
                className="customCheckbox"
              >
                <Form.Label>
                  <b>{t('labels.report_lang')} :</b>
                </Form.Label>
                <br />
                <Form.Control as="select" name='language' value={report_language} className="select-language" onChange={(e) => this.handleLanguageSelect(e)}>
                  <option selected value='select'>--{t('labels.select_lang')}--</option>
                  {languageList()?.map((lang, idx) => (
                    <option key={idx} value={lang.value}>{lang.label}</option>)
                  )}

                </Form.Control>
                <Form.Text className="text-muted">
                  {check_lang_error ? t('validations.select_lang') : null}
                </Form.Text>
              </Form.Group>
              {/* Language select end */}

              {/* Send result - start  */}
              <Form.Group
                controlId="formBasicCheckbox"
                className="customCheckbox"
              >
                <Form.Label>
                  <b>{t('labels.send_result_to')} :</b>
                </Form.Label>
                <br />
                <input
                  checked={this.state.user_mail}
                  onClick={() => {
                    this.setState({ user_mail: !this.state.user_mail });
                  }}
                  type="checkbox"
                  label="User Email"
                />
                <Form.Label style={{ "padding-right": "40px" }}>
                  {t('labels.customer')}
                </Form.Label>
                <input
                  checked={this.state.practitioner_email}
                  onClick={() => {
                    this.setState({
                      practitioner_email: !this.state.practitioner_email,
                    });
                  }}
                  type="checkbox"
                  label="Practitioner Email"
                />
                <Form.Label>{t('labels.practitioner')}</Form.Label>
                <Form.Text className="text-muted">
                  {check_user_error ? t('validations.select_option') : null}
                </Form.Text>
              </Form.Group>
              {/* Send result - end  */}

            </Form>
          </Modal.Body>
          {open ? (
            <Modal.Footer>
              <div>
                <Button className="bgBtn mr-3" onClick={this.handleOpen}>
                  {t('buttons.submit')}
                </Button>
              </div>
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              <h5>{t('release_results_confirmation')}</h5>
              <div>
                <Button
                  className="bgBtn mr-3"
                  onClick={() => {
                    this.ReleasePDFs();
                  }}
                >
                  {release_loading && (
                    <Spinner
                      className="spinnerClass"
                      animation="border"
                      variant="light"
                      size="sm"
                      style={{ float: "right", marginLeft: "25px" }}
                    />
                  )}
                  {t('buttons.confirm')}
                </Button>
                <Button
                  className="bgBtn mr-3"
                  onClick={() => {
                    this.handleClose1();
                    this.handleOpen();
                  }}
                >
                  {t('buttons.cancel_caps')}
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>

        <Modal show={subInfoModalShow} onHide={() => { this.setState({ subInfoModalShow: false }) }} className="reportaccess_warning" centered size="sm" >
          <Modal.Header closeButton>
            <Modal.Title>{t('title.imp_info')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="user-management-modal">
            <div className="subscription-text" style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: subMessage }} ></div>
            <Button className="bgBtn mr-3" style={{ marginLeft: "34%", marginTop: "2%" }} type="submit" size='sm' onClick={() => { this.setState({ subInfoModalShow: false }) }}>{t('buttons.ok')}</Button>
          </Modal.Body>
        </Modal>

        {edit1 && (
          <>
            <EditUser
              show={edit1}
              closeModal={this.closeEditModal}
              UserDetails={edit_user}
              updateAPI={this.updateUser}
              showAlert={showAlert}
              clearAlert={this.clearAlert}
              submitted={submitted}
              title={t('title.edit_customer')}
              type="edit"
              filter_type={this.props.filter_type}
              partnerList={this.props.partnerList}
              subPartnerList={this.props.subPartnerList}
              practitioner_type={this.props.type}
              practitioner_id={this.state.practitioner_id}
              departments={this.state.departments}
              practitionerList={this.props.practitionerList}
              access={this.props.access}
            ></EditUser>
          </>
        )}
        <Modal
          show={add_child_kit}
          onHide={this.closeChildKit}
          centered
          className="trackingModal"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('title.add_additional_kit')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props?.access?.blood_test && (
              <Form.Group style={{ display: "flex", gap: "10px" }}>
                <Form.Check
                  type="radio"
                  label={t('labels.genetic')}
                  name="rkitType"
                  id="genetic"
                  value="genetic"
                  checked={this.state.kit_type === "genetic"}
                  onChange={(e) => this.switchKitType(e, "genetic")}
                />

                <Form.Check
                  type="radio"
                  label={t('labels.blood_test')}
                  name="rkitType"
                  id="blood"
                  value="blood"
                  checked={this.state.kit_type === "blood"}
                  onChange={(e) => this.switchKitType(e, "blood")}
                />
              </Form.Group>
            )}

            <Form.Group controlId="formKitId">
              <Form.Label>{t('labels.kit_id')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('placeholder.child_kit')}
                name="kitId"
                value={child_kit}
                onChange={this.handleChange}
              />
              <Form.Text className="text-muted">{kitErrorMsg}</Form.Text>
            </Form.Group>

            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={() => this.addChildKit(user)}
              disabled={child_kit !== "" && !kitErrorMsg ? null : true}
            >
              {this.state.childLoader ? (
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="light"
                  size="sm"
                  style={{ float: "center" }}
                />
              ) : t('buttons.add_kit')}
            </Button>
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.closeChildKit}
            >
              {t('buttons.cancel')}
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          show={activesubscription}
          onHide={this.closeAppSubscription}
          centered
          className="trackingModal"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Form.Text className="subscription_text">
            <h6>
              {t('messages.fb_dna12_active_subscription', {
                date1: endDate,
                date2: dna12endDate
              })}
              {t('messages.subscription_end')}
            </h6>
          </Form.Text>
          <Modal.Body>
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.closeAppSubscription}
            >
              Close
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          show={add_subscription}
          onHide={this.closeSubscription}
          centered
          className="subscriptionModal"
          size="sm"
        >
          <Modal.Header closeButton style={{ padding: "1.5rem" }}>
            <Modal.Title>
              {subscriptiondata.portalsubscriptions && subscribed ? t('title.edit_subscription') : t('title.manage_subscription')}
            </Modal.Title>
          </Modal.Header>
          <Form.Text className="subscription_text">
            <h6>{t('labels.select_subscription_type')} :</h6>
          </Form.Text>
          <Form.Group className="subscription-form">
            <Form.Control
              as="select"
              name="tracking"
              onChange={this.subscriptionTypeChecked.bind(this)}
            >
              <option key="sp2" value='select'>--{t('labels.select_subscription')}--</option>
              {this.state.subscription.map((child, index) => (
                <option key={child.key} value={child.key}>
                  {child.text}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {subscriptiondata.appsubscribed == true ||
            (dna12subscriptiondata.appSubscribed == true && (
              <Form.Text className="subscription_text">
                {this.state.subscription_name == "face_brain" && endDate && (
                  <h6>{t('messages.fb_active_subscription', { endDate: endDate })}</h6>
                )}
                {this.state.subscription_name == "dna12" && dna12endDate && (
                  <h6>{t('messages.dna12_active_subscription', { endDate: dna12endDate })}</h6>
                )}
              </Form.Text>
            ))}
          {subscriptiondata.appsubscribed == true || dna12subscriptiondata.appSubscribed == true ? "" :
            <Form.Text className="subscription_text">
              {this.state.subscription_name.length > 0 && this.state.subscription_name !== "select" ? t('validations.subscription_type', { subscription_name: this.state.subscription_text }) : null}
            </Form.Text>
          }
          <Modal.Body>
            <Form.Group style={{ display: "flex", gap: "10px" }}>
              <Form.Check
                type="radio"
                label={t('subscription_period.monthly')}
                name="rkitType"
                id="monthly"
                value="monthly"
                checked={this.state.subscription_type === "monthly"}
                onChange={(e) => this.switchSubscriptionType(e, "monthly")}
                disabled={(this.state.subscription_name == "face_brain" && subscriptiondata.appsubscribed == true) || (this.state.subscription_name == "dna12" && dna12subscriptiondata.appSubscribed == true)}
              />

              <Form.Check
                type="radio"
                label={t('subscription_period.yearly')}
                name="rkitType"
                id="yearly"
                value="yearly"
                checked={this.state.subscription_type === "yearly"}
                onChange={(e) => this.switchSubscriptionType(e, "yearly")}
                disabled={(this.state.subscription_name == "face_brain" && subscriptiondata.appsubscribed == true) || (this.state.subscription_name == "dna12" && dna12subscriptiondata.appSubscribed == true)}
              />
              <Form.Check
                type="radio"
                label={t('subscription_period.forever')}
                name="rkitType"
                id="forever"
                value="forever"
                checked={this.state.subscription_type === "forever"}
                onChange={(e) => this.switchSubscriptionType(e, "forever")}
                disabled={(this.state.subscription_name == "face_brain" && subscriptiondata.appsubscribed == true) || (this.state.subscription_name == "dna12" && dna12subscriptiondata.appSubscribed == true)}
              />
            </Form.Group>

            {subOpen ? (
              <Button
                className="bgBtn mr-3"
                type="submit"
                size="sm"
                onClick={() => this.checkSubscription(user)}
                disabled={(this.state.subscription_name == "face_brain" || this.state.subscription_name == "dna12") && this.state.subscription_type !== "" ? null : true}
              >
                {t('buttons.submit')}
              </Button>
            ) : (
              <>
                <h6>{t('cancel_subscription_confirmation')}</h6>
                <div>
                  <Button className="bgBtn mr-3" onClick={() => this.addSubscription(user)}>
                    {subscriptionLoader ?
                      <Spinner className="spinnerClass" animation="border" variant="light" size="sm" style={{ float: "center" }} />
                      : subscription_button
                    }
                  </Button>
                  <Button
                    className="bgBtn mr-3"
                    onClick={() => {
                      this.closeSubscription();
                    }}
                  >
                    {t('buttons.cancel_caps')}
                  </Button>
                </div>
              </>
            )}

            {/* <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={() => this.addSubscription(user)}
              disabled={(this.state.subscription_name == "face_brain" || this.state.subscription_name == "dna12") && this.state.subscription_type !=="" ? null : true}
            >
                {this.state.subscriptionLoader?
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="light"
                  size="sm"
                  style={{ float: "center" }}
                />
                :
                subscription_button
                
              }       
            </Button> */}
            {((subscriptiondata.portalsubscribed &&
              this.state.subscription_name == "face_brain") ||
              (dna12subscriptiondata.portalSubscribed &&
                this.state.subscription_name == "dna12")) && (
                <Button
                  className="bgBtn mr-3"
                  type="submit"
                  size="sm"
                  onClick={() => this.cancelSubscription(user)}
                >
                  {this.state.cancelsubscriptionLoader ? (
                    <Spinner
                      className="spinnerClass"
                      animation="border"
                      variant="light"
                      size="sm"
                      style={{ float: "center" }}
                    />
                  ) : t('buttons.cancel_subscription')}
                </Button>
              )}
            {(subscriptiondata.portalsubscribed == true ||
              dna12subscriptiondata.portalSubscribed == true) && (
                <div className="subTable">
                  <div style={{ textAlign: "center" }}>{t('subscription_details')}</div>
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>{t('subscription_table.subscription_name')}</th>
                        <th>{t('subscription_table.subscription_type')}</th>
                        <th>{t('subscription_table.from')}</th>
                        <th>{t('subscription_table.to')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptiondata.portalsubscribed == true && (
                        <tr>
                          <td>{t('subscription_table.face_brain')}</td>
                          <td>
                            {camelize(this.state.subscriptiondata.portalsubscriptions.subscription_type)}
                          </td>
                          <td>{startDate}</td>
                          <td>{endDate == "Invalid date" ? "---" : endDate}</td>
                        </tr>
                      )}
                      {dna12subscriptiondata.portalSubscribed == true && (
                        <tr>
                          <td>{t('subscription_table.dna12')}</td>
                          <td>
                            {camelize(this.state.dna12subscriptiondata.portalSubscription.subscription_type)}
                          </td>
                          <td>{dna12startDate}</td>
                          <td>
                            {dna12endDate == "Invalid date" ? "---" : dna12endDate}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
          </Modal.Body>
        </Modal>
        <Modal
          show={confirm_delete}
          onHide={this.closeDelete}
          centered
          className="trackingModal"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('title.delete_customer')}: {edit_user.email}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('delete_customer_confirmation')}
            <br /> <br />
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.confirmDelete}
            >
              {this.state.submitted ? (
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="light"
                  size="sm"
                  style={{ float: "center" }}
                />
              ) : t('buttons.yes')}
            </Button>
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.closeDelete}
            >
              {t('buttons.no')}
            </Button>
          </Modal.Body>
        </Modal>

        <Modal
          show={confirm_permission}
          onHide={this.closeAccess}
          centered
          className="trackingModal"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('title.confirm_request')}: {user.email}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('report_access_confirmation')}
            <br />
            <br />
            <p style={{ color: "red" }}>
              {t('report_access_note')}
            </p>{" "}
            <br />
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.confirmAccess}
            >
              {this.state.submitted ? (
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="light"
                  size="sm"
                  style={{ float: "center" }}
                />
              ) : (
                t('buttons.yes')
              )}
            </Button>
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.closeAccess}
            >
              {t('buttons.no')}
            </Button>
          </Modal.Body>
        </Modal>

        <Modal
          show={confirm_app_access}
          onHide={this.closeAppAccess}
          centered
          className="trackingModal"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('title.confirm_request')}: {user.email}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('app_access_confirmation')}
            <br />
            <br />
            <p style={{ color: "red" }}>
              {t('app_access_note')}
            </p>{" "}
            <br />
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.confirmAppAccess}
            >
              {this.state.submitted ? (
                <Spinner
                  className="spinnerClass"
                  animation="border"
                  variant="light"
                  size="sm"
                  style={{ float: "center" }}
                />
              ) : (
                t('buttons.yes')
              )}
            </Button>
            <Button
              className="bgBtn mr-3"
              type="submit"
              size="sm"
              onClick={this.closeAppAccess}
            >
              {t('buttons.no')}
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toUpperCase() : word.toUpperCase();
  }).replace(/\s+/g, " ");
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
  error: alertActions.error,
};

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(DataTable);
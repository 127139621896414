import React, { useEffect, useMemo, useState } from "react";
import AsyncSelect from "react-select/async";
import { apiService } from "../../services/api_service";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";
/**
 * Todo - Dropdown Comparison -> second dropdown selected option will be disable if it is already selected in dropdown1
 * https://stackoverflow.com/questions/76283039/how-to-sort-selected-items-to-the-top-of-the-list-for-react-select-async
 * Target Page - Congintal Report  
 */


const AsyncLookup = ({ type, value, onChange, ...props }) => {
  const [selectedValue, setSelectedValue] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (value?.value !== "select") {
      setSelectedValue(value);
    }
  }, [value]);

  const customStyles = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        paddingRight: "10px", // Adjust the space for the icon on the right side
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "8px", // Adjust padding for the search icon
        right: "10px", // Adjust the positioning of the icon
      }),
    }),
    []
  );

  const config = useMemo(() => {
    const loadOptions = async (inputValue) => {
      try {
        if (inputValue.length < 3) return;

        const response = await apiService.AccessUserslistByUserData(
          type,
          inputValue
        );

        return response ?? [];
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    };

    const handleChange = (selectedOption) => {
      setSelectedValue(selectedOption);
      onChange?.(selectedOption);
    };

    let config = {
      ...props,
      cacheOptions: true,
      className: "select-options",
      onChange: handleChange,
      loadOptions,
      value: selectedValue,
      menuIsOpen: props?.inputValue?.length > 0,
      styles: customStyles, // Apply custom styles
      placeholder: t('placeholder.type_to_search'),
      loadingMessage: () => t('lookup.loading'),
      noOptionsMessage: () => t('lookup.no_options')
    };

    if (selectedValue && selectedValue.value !== "select") {
      config.isClearable = true;
      config.components = {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      };
    } else {
      config.components = {
        DropdownIndicator: () => <FaSearch className="search-icon" />,
        IndicatorSeparator: () => null,
      };
    }

    return config;
  }, [props, selectedValue, customStyles, type, onChange, t]);

  return <AsyncSelect {...config} />;
};

export default AsyncLookup;

import React from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal, Spinner } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

import { apiService } from '../../services/api_service'
import { alertActions } from '../../actions/alert-action';

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SearchBox from "../../components/SearchBox/SearchBox";
import FilerButton from "../../components/UserMenu/FilerButton";
import DataTable from "../../components/Tables/DataTable";
import Pagination from "../../components/Pagination/Pagination";
import PageFiltration from "../../components/PageHead/PageFiltration"
import Intro from "./intro";

class Home extends React.Component {
  constructor(props) {
    super(props);

    console.log(this.props.user?.email);

    this.state = {
      email: "",
      practitioner_id: "",
      practitioner_type: "",
      partner_id: null,
      clinic_id: null,
      practitionerId: "",
      name: "",
      type: "",
      loading: false,
      request_loading: false,
      user_details: [],
      all_users: [],
      user_type: [
        { label: "all", value: "all" },
        { label: 'mobile_user', value: "mobile" },
        { label: 'pract_user', value: "practitioner" },
        { label: 'partner_user', value: "partner" },
        { label: 'clinic_user', value: "clinic" },
      ],
      submitted: false,
      showAlert: false,
      showAlert2: false,
      practitionerDetails: {},
      practitioner_list: [],
      clinic_list: [],
      partner_list: [],
      filter_type: "all",
      skip: 0,
      limit: 10,
      pages: [0],
      total: 0,
      intro: false,
      show: false,
      search: "",
      wait_loading: false,
      kit_status: "",
      filter: {
        practitionerID: "",
        clinic_id: "",
        partner_id: "",
        kit_status: "",
      },
      access: {},
      blood_test_access: false,
    };
    this.practitionerList = this.practitionerList.bind(this);
    this.partnerList = this.partnerList.bind(this);
    this.subPartnerList = this.subPartnerList.bind(this);
    this.UserListByFiltration = this.UserListByFiltration.bind(this);
    this.addUserHandler = this.addUserHandler.bind(this);
    this.closeUserHandler = this.closeUserHandler.bind(this);
    this.updateUserDetails = this.updateUserDetails.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
      this.setState({ loading: true });
      try {
        let practitioner_details = nextProps.user;
        let practitioner_id = practitioner_details?._id;
        if (practitioner_id) {
          practitioner_id = btoa(practitioner_id);

          // let intro = false;

          await this.practitionerList({ practitioner_id });
          if (practitioner_details.type == "admin") {
            await this.partnerList({ practitioner_id: practitioner_id });
          }
          if (
            practitioner_details.type == "admin" ||
            practitioner_details.type == "partner"
          ) {
            await this.subPartnerList({ practitioner_id: practitioner_id });
          }
          let introduct = await apiService.PractitionerIntro(
            practitioner_details.email
          );
          let { intro } = introduct;

          let condition = {};
          condition.filter = {};
          condition.practitioner_id = practitioner_id;
          condition.skip = this.state.skip;
          condition.limit = this.state.limit;
          let user_details = await apiService.GetAllUsers(condition);
          let total =
            user_details[0].total.length > 0
              ? user_details[0].total[0].count
              : 0;
          let pages = [...Array(Math.ceil(total / this.state.limit)).keys()];
          let filter_type = [
            { label: "all", value: "all" },
            { label: 'mobile_user', value: "mobile" },
            { label: 'pract_user', value: "practitioner" },
          ];
          if (practitioner_details.type == "clinic") {
            filter_type.push({ label: 'clinic_user', value: "clinic" });
          } else if (
            practitioner_details.type == "admin" ||
            practitioner_details.type == "partner"
          ) {
            filter_type.push(
              { label: 'partner_user', value: "partner" },
              { label: 'clinic_user', value: "clinic" }
            );
          }
          const filter = filter_type.map((item) => ({
            ...item,
            label: t(`customer_filter.${item.label}`),
          }))
          this.bloodTestAccess(practitioner_details.access_controls);
          this.setState({
            email: practitioner_details.email,
            practitioner_id,
            name:
              practitioner_details.first_name + practitioner_details.last_name,
            type: practitioner_details.type,
            user_details: user_details[0].users,
            all_users: user_details[0].users,
            loading: false,
            practitioner_type: practitioner_details.type,
            practitionerDetails: practitioner_details,
            total,
            pages,
            skip: 0,
            user_type: filter,
            intro,
            show: true,
            access: practitioner_details.access_controls,
            ...nextProps.user
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  bloodTestAccess(access) {
    let blood_test_access = false;
    if (access && access.blood_test) blood_test_access = true;
    this.setState({ blood_test_access });
    console.log({ access, blood_test_access });
  }

  async practitionerList(condition) {
    condition.limit = this.state.limit;
    condition.skip = this.state.skip;
    let practitioners = await apiService.practitionerList(condition);
    // console.log('practitioners',practitioners)
    let total = 0;
    let pages = [0];
    let practitioner_list = [];
    if (practitioners && practitioners[0]) {
      // console.log("practitioner", practitioners[0])
      // total = practitioners[0].total.length>0?practitioners[0].total[0].count:0
      // pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
      // practitioner_list = practitioners[0].users
      practitioner_list = practitioners;
    }

    this.setState({ practitioner_list, total, pages });
  }
  async partnerList(condition) {
    condition.limit = this.state.limit;
    condition.skip = this.state.skip;
    let total = 0;
    let pages = [0];
    let partner_list = [];
    let partners = await apiService.PartnerList(condition);
    if (partners && partners[0]) {
      // total = partners[0].total.length>0?partners[0].total[0].count:0
      // pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
      // partner_list = partners[0].users
      partner_list = partners;
    }

    this.setState({ partner_list, total, pages });
  }
  async subPartnerList(condition) {
    condition.limit = this.state.limit;
    condition.skip = this.state.skip;
    let total = 0;
    let pages = [0];
    let clinic_list = [];
    // console.log(condition)
    let subPractitioners = await apiService.ClinicList(condition);
    if (subPractitioners && subPractitioners[0]) {
      // total =   subPractitioners[0].total.length>0?subPractitioners[0].total[0].count:0
      // pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
      // clinic_list = subPractitioners[0].users
      clinic_list = subPractitioners;
    }
    this.setState({ clinic_list, total, pages });
  }

  async updateStatus(updated_user) {
    updated_user.loading = true;
    this.setState({
      data: this.state.user_details.map((el) =>
        el.id === updated_user.id ? updated_user : el
      ),
    });
    let userId = Buffer.from(String(updated_user._id)).toString("base64");
    let practitionerId = Buffer.from(String(this.props.user?._id)).toString("base64");
    let requestAccess = await apiService.requestAccess(practitionerId, userId);
    if (requestAccess.code === 200) {
      updated_user.report_access_status = this.props.t("customers_table.request_pending");
      updated_user.is_accepted = false;
    }
    updated_user.loading = false;
    this.setState({
      data: this.state.user_details.map((el) =>
        el.id === updated_user.id ? updated_user : el
      ),
    });
  }

  async updateUsersList(userList, total, search) {
    let pages = [...Array(Math.ceil(total / this.state.limit)).keys()];
    this.setState({ user_details: userList, total, pages, skip: 0, search });
  }

  async CreateUser(body) {
    this.setState({ submitted: true });
    // body.practitioner_id= this.props.user?.id
    console.log("body", body);
    let savedUser = await apiService.CreateUser(body);
    // if(savedUser.length==0){
    //     this.setState({submitted:false, showAlert:true})
    //     return
    // }
    this.handlePagination(this.state.skip);
    this.setState({ submitted: false, showAlert2: false });
    return savedUser;
  }

  async UserByType(value) {
    this.setState(
      {
        filter_type: value,
        skip: 0,
      },
      async () => {
        await this.GetAllUsers({ filter_type: value });
      }
    );
  }

  async GetAllUsers(cond) {
    this.setState({ loading: true });
    let condition = {};
    condition.filter = {};
    condition.skip = this.state.skip;
    condition.limit = this.state.limit;
    if (this.state.filter.practitionerID) {
      condition.filter.practitioner_id = this.state.filter.practitionerID;
    }
    if (this.state.filter.partner_id) {
      condition.filter.partner_id = this.state.filter.partner_id;
    }
    if (this.state.filter.clinic_id) {
      condition.filter.clinic_id = this.state.filter.clinic_id;
    }
    if (this.state.filter.kit_status) {
      condition.filter.kit_status = this.state.filter.kit_status;
    }
    if (this.state.filter.result_type) {
      condition.filter.result_type = this.state.filter.result_type;
    }
    if (this.props.user?.id) {
      condition.practitioner_id = this.props.user?.id;
    }
    if (this.state.filter_type) {
      condition.type = this.state.filter_type;
    }

    if (this.state.search) {
      condition.search_item = this.state.search;
    }
    condition["practitioner_id"] = btoa(this.props.user._id);
    let userlist = await apiService.GetAllUsers(condition);
    let total = userlist[0].total.length > 0 ? userlist[0].total[0].count : 0;
    let pages = [...Array(Math.ceil(total / this.state.limit)).keys()];
    this.setState({
      loading: false,
      user_details: userlist[0].users,
      total,
      pages,
      condition,
    });
  }

  clearAlert() {
    this.setState({ showAlert2: false });
  }
  addUserHandler = () => {
    // console.log('reqqqqqqqqqqqqqqqqqqqqq')
    this.setState({ showAlert2: true });
  };
  closeUserHandler = () => {
    this.setState({ showAlert2: false });
  };
  componentDidUpdate() {
    if (this.state.showAlert === true) {
      setTimeout(() => {
        this.clearAlert();
      }, 3000);
    }
  }

  async UserListByFiltration(value) {
    this.setState(
      {
        filter: value,
        skip: 0,
      },
      async () => {
        await this.GetAllUsers({ filter: value });
      }
    );
  }
  async UserListBYSearch(value) {
    this.setState(
      {
        search: value,
        skip: 0,
      },
      async () => {
        await this.GetAllUsers({ search: value });
      }
    );
  }

  async handlePagination(skip) {
    this.setState({ wait_loading: true });
    let user_details = [],
      total = 0,
      pages = [];
    try {
      let pid = this.props.user?.id;
      let condition = {};
      condition.practitioner_id = pid;
      condition.skip = skip * this.state.limit;
      condition.limit = this.state.limit;
      condition.type = this.state.filter_type;
      condition.filter = {};
      if (this.state.filter.partner_id) {
        condition.filter.partner_id = this.state.filter.partner_id;
      }
      if (this.state.filter.clinic_id) {
        condition.filter.clinic_id = this.state.filter.clinic_id;
      }
      if (this.state.filter.practitionerID) {
        condition.filter.practitioner_id = this.state.filter.practitionerID;
      }

      if (this.state.filter.kit_status) {
        condition.filter.kit_status = this.state.filter.kit_status;
      }

      if (this.state.filter.result_type) {
        condition.filter.result_type = this.state.filter.result_type;
      }

      if (this.state.search !== "") {
        condition.search_item = this.state.search;
      }
      condition["practitioner_id"] = btoa(this.props.user._id);
      user_details = await apiService.GetAllUsers(condition);
      total =
        user_details[0].total.length > 0 ? user_details[0].total[0].count : 0;
      pages = [...Array(Math.ceil(total / this.state.limit)).keys()];

      this.setState({
        user_details: user_details[0].users,
        all_users: user_details[0].users,
        skip: skip,
        total,
        pages,
        wait_loading: false,
      });
    } catch (err) {
      this.setState({ wait_loading: false });
      console.log("error", err);
    }

    // this.updateList()
  }

  updateUserDetails = async (user, type) => {
    // this.handlePagination(this.state.skip)
  };

  handleClose() {
    this.setState({ show: false, intro: false });
  }

  render() {
    const { t } = this.props;
    const { show } = this.state;
    const intro = (
      <Modal
        show={show}
        onHide={() => this.handleClose()}
        centered
        className="trackingModal"
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('introduction')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Intro />
          <button
            className={"continuebtn"}
            onClick={() => this.setState({ intro: false, show: false })}
          >
            {t('buttons.close')}
          </button>
        </Modal.Body>
      </Modal>
    );
    return (
      <div className="home-wrp">
        <div className="homepagehead">
          {this.state.intro ? intro : null}
          <div className="row justify-content-between align-items-end">
            <div className="col-12 col-sm-auto">
              <h1>{t('heading.customers')}</h1>
              <BreadCrumb title={t('heading.customers')}></BreadCrumb>
            </div>

            <div className="col-12 col-sm-auto d-flex">
              <FilerButton
                types={this.state.user_type}
                updateList={this.UserByType.bind(this)}
              ></FilerButton>
              <SearchBox
                UserListBYSearch={this.UserListBYSearch.bind(this)}
                limit={this.state.limit}
                skip={this.state.skip}
              ></SearchBox>
            </div>
          </div>

          <PageFiltration
            show1={this.state.showAlert2}
            addUserHandler={this.addUserHandler.bind(this)}
            closeUserHandler={this.closeUserHandler.bind(this)}
            type={this.state.practitioner_type}
            createAPI={this.CreateUser.bind(this)}
            submitted={this.state.submitted}
            alert={this.state.showAlert}
            clearAlert={this.clearAlert.bind(this)}
            showAlert2={this.state.showAlert2}
            practitioner_id={this.props.user?._id}
            practitionerList={this.state.practitioner_list}
            partnerList={this.state.partner_list}
            subPartnerList={this.state.clinic_list}
            UserListByFiltration={this.UserListByFiltration.bind(this)}
            practitioners={this.practitionerList.bind(this)}
            subPartners={this.subPartnerList.bind(this)}
            updateUserDetails={this.updateUserDetails.bind(this)}
            filter_type={this.state.filter_type}
            practitionerDetails={this.state.practitionerDetails}
            access={this.state.access}
            loader={this.state.loading}
          ></PageFiltration>
        </div>
        <div className="patientsTable">
          {this.state.wait_loading ? (
            <Spinner
              className="spinnerClass"
              animation="border"
              style={{
                float: "center",
                position: "absolute",
                margin: "10% 10%",
                left: "45%",
                top: "25%",
              }}
            />
          ) : null}
          <DataTable
            userDetails={this.state.user_details}
            practitionerId={this.props.user?.id}
            updateUser={this.updateStatus.bind(this)}
            loading_status={this.state.request_loading}
            loader={this.state.loading}
            practitionerDetails={this.state.practitionerDetails}
            updateUserDetails={this.updateUserDetails.bind(this)}
            filter_type={this.state.filter_type}
            partnerList={this.state.partner_list}
            subPartnerList={this.state.clinic_list}
            type={this.state.practitioner_type}
            practitionerList={this.state.practitioner_list}
            getAllUsers={this.GetAllUsers.bind(this)}
            access={this.state.access}
            blood_test_access={this.state.blood_test_access}
          ></DataTable>
        </div>
        <div className="d-flex justify-content-end">
          <Pagination
            paginationClick={(num) => this.handlePagination(num)}
            total={this.state.total}
            pages={this.state.pages}
            skip={this.state.skip}
            limit={this.state.limit}
          ></Pagination>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { current, authentication } = state;
  const { loggingIn, user } = authentication;

  console.log(user);
  return { loggingIn, current, user };
}

const actionCreators = {
  success: alertActions.success,
};

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(Home);

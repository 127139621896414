import React from "react";
import { RiHeartPulseFill } from "react-icons/ri";
import { withTranslation } from "react-i18next";

import OverviewRecentDetails from "../../components/OverviewCard/OverviewRecentDetails";

class OverviewCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { details, t } = this.props
        return (
            <>
                <div className="overviewcard-wrp">
                    <div className="overviewHead">
                        <h3>{details.Title} <small>{t('recent')}</small></h3>
                        <figure>
                            <img src={details.main_img} className="filterit"></img>
                        </figure>
                        <h4> {details.avg}</h4>
                        <ul className="list-unstyled">
                            {details.value1 && <li> <img src={details.list_img1} className="filterit"></img>{details.value1}</li>}
                            {details.value2 && <li> <img src={details.list_img2} className="filterit"></img>{details.value2}</li>}
                            {details.value3 && <li> <img src={details.list_img3} className="filterit"></img>{details.value3}</li>}
                        </ul>
                    </div>

                    {details.Title === "Active Time" ?
                        <div className="overviewDetails">
                            <ul className="activeTimeDetails">
                                <li> <img src="/images/lifestyle/stepindicator1.svg" className="filterit"></img>{details.cal}</li>
                                <li> <img src="/images/lifestyle/stepindicator2.svg" className="filterit"></img>{details.distance}</li>
                            </ul>

                        </div>
                        :
                        details.Title === "Heart Rate" ?
                            <div className="overviewDetails">
                                <ul className="list-unstyled">
                                    <li>
                                        <RiHeartPulseFill
                                            color='red'
                                            size={20} /> {t('lifestyle_graph.min_hr')} - {details.min}
                                    </li>
                                    <li> <RiHeartPulseFill
                                        color='red'
                                        size={20} /> {t('lifestyle_graph.max_hr')} - {details.max}</li>
                                </ul>

                            </div>
                            :
                            <OverviewRecentDetails
                                icon={details.icons}
                                details={details}
                            ></OverviewRecentDetails>}
                </div>
            </>
        )

    }

}

export default withTranslation()(OverviewCard)
import React from "react";
import { Modal, Form, Spinner, Button } from 'react-bootstrap'
import Select from 'react-select'
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import UserMenu from "../../components/UserMenu/UserMenu";
import { apiService } from "../../services/api_service";
import { userActions } from "../../actions"
import { userService } from "../../services";
import { nameRegex, usernameRegex } from "../../constants";
import { getPartnerUrlPrefix } from '../../helpers/partner';


class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            username: "",
            id: "",
            email: "",
            partner: null,
            submitted: false,
            show: false,
            emailError: false,
            first_nameError: false,
            user_nameError: false,
            modal: [],
            phoneError: false,
            phoneValid: false,
            logo: '/images/logo.svg',
            logoWidth: '26%',
            logoheight: '',
            objectPosition: '',
            objectFit: '',
            width: '',
            height: '',
            availablePartners: [],
            selectedPartner: { label: this.props.t('labels.select_a_partner'), value: "select" },
            practitioner_id: ""
        }
        this.handleexit = this.handleexit.bind()
        this.handleModal = this.handleModal.bind()
    }

    componentDidMount() {
        if (this.props.user?._id) {
            this.updateUserState(this.props.user);
        }
    }

    async componentDidUpdate(prevProps) {
        // Check if the user prop has changed
        if (this.props.user?._id && this.props.user?._id !== prevProps.user?._id) {
            try {
                await this.updateUserState(this.props.user);
            } catch (error) {
                console.log("Error updating user state:", error);
            }
        }
    }

    async updateUserState(userData) {
        const partner_id = userData?.partner_id;
        let logo = this.logoPick(partner_id);
        const practitioner_id = btoa(userData._id);

        if (practitioner_id) {
            // Fetch available partners
            let availablePartners = await apiService.getAvailablePartners({
                // practitioner_id,
            });
            let selectedPartner = availablePartners.filter((p) => p.value == partner_id);

            // Update the state with the new user data
            this.setState({
                practitioner_id,
                availablePartners,
                selectedPartner,
                username: userData?.name || "",
                id: userData?.id || "",
                email: userData?.email || "",
                partner: userData?.partner_id || null,
                ...userData,
            });
        } else {
            // Fallback state update if practitioner_id is invalid
            this.setState({
                username: userData?.name || "",
                id: userData?.id || "",
                email: userData?.email || "",
                partner: userData?.partner_id || null,
            });
        }
    }


    handleexit = async (e) => {
        sessionStorage.setItem("sessionExpired", false);
        const partner = this.props.user?.partner_id || null;
        const url = `${getPartnerUrlPrefix(partner)}/signin`
        await userService.logout().then(() => {
            window.location.replace(url);
        });
        this.props.history.push(url);
    }


    handleModal = async () => {
        let data = await apiService.viewManagementUser("self", this.state.id);
        if (!Array.isArray(data)) {
            this.setState({ show: !this.state.show, modal: data });
        }
    }

    handleEdit = async () => {
        this.setState({ loading: true })
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        let modal = this.state.modal
        if (modal["email"] && modal["first_name"] && modal["phone"]) {
            let condition = {
                first_name: this.state.modal.first_name,
                last_name: this.state.modal.last_name,
                //user_name: this.state.modal.user_name,
                //email: this.state.modal.email,
                phone: this.state.modal.phone
            }
            let data = await apiService.editManagementUser(this.state.practitioner_id, condition)

            if (data) {
                console.log("Successfully updated user")
                this.props.updateUserData({ ...this.props.user, ...condition })
                this.setState({ message: "Successfully Updated User", loading: false, usersCollection: [], show: false })
            } else {
                console.log("Something went wrong", data)
                this.setState({ message: data, loading: false, usersCollection: [] })
            }
        } else {
            this.setState({
                emailError: modal["email"] ? modal["email"].match(regexEmail) : modal["email"] ? false : true,
                first_nameError: modal["first_name"] ? false : true,
                phoneError: modal["phone"] ? false : true,
                loading: false
            })
        }
    }

    async validateEmail(email) {
        let result = await apiService.validateUMEmail(email)
        if (result == true) {
            return true
        } else {
            return false
        }

    }

    async handleChange(e) {
        const { t } = this.props;
        const { name, value } = e.target;
        const updatedModal = { ...this.state.modal, [name]: value };
        const updatedState = { modal: updatedModal, message: "" };
        const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        const phoneno = /^\+\d+$/;

        switch (name) {
            case "email":
                updatedState.emailError = !value.match(regexEmail);
                updatedState.emailExist = false;
                break;

            case "user_name":
                updatedState.user_nameError = !value || !usernameRegex.test(value);
                updatedState.user_nameMessage = updatedState.user_nameError && value ? t('validations.username') : "";
                break;

            case "first_name":
                updatedState.first_nameError = !value || !nameRegex.test(value);
                updatedState.first_nameMessage = updatedState.first_nameError && value ? t('validations.first_name') : "";
                break;

            case "last_name":
                updatedState.last_nameError = !value || !nameRegex.test(value);
                updatedState.last_nameMessage = updatedState.last_nameError && value ? t('validations.last_name') : "";
                break;

            case "phone":
                if (updatedModal["email"] && updatedModal["first_name"]) {
                    updatedState.phoneValid = !value.match(phoneno);
                    updatedState.phoneError = !value;
                } else {
                    updatedState.emailError = updatedModal["email"] ? !updatedModal["email"].match(regexEmail) : false;
                    updatedState.first_nameError = !updatedModal["first_name"];
                }
                break;

            default:
                break;
        }

        // Validate email if it's filled and matches the pattern
        if (updatedModal.email && updatedModal.email.match(regexEmail)) {
            const emailValid = await this.validateEmail(updatedModal.email);
            updatedState.emailExist = emailValid;
        }

        this.setState(updatedState);
    }


    logoPick(partner_id) {
        let logo, logoWidth = '26%', logoheight, objectPosition, objectFit, width, height
        switch (partner_id) {
            case 1:
                logo = '/images/logo.svg';
                break;
            case 7:
                logo = '/images/logos/biosynergy.png';
                logoWidth = '100%';
                logoheight = '100%';
                objectPosition = 'center';
                objectFit = 'contain';
                width = '100px';
                height = '65px';
                break;
            case 13:
                logo = '/images/logos/TNLogo.png';
                break;
            case 25:
            case 31:
            case 33:
                logo = '/images/logos/hc_logo.png'
                logoWidth = '26%';
                break;
            case 26:
                logo = '/images/logos/truepi.png';
                break;
            case 28:
                logo = '/images/logos/mamh.png';
                logoWidth = '26%';
                break;
            case 45:
                logo = '/images/logos/promake_logo.png';
                logoWidth = '19%';
                break;
            case 47:
                logo = '/images/logos/longevity-clinic.jpg';
                logoWidth = '47%';
                break;
            case 46:
                logo = '/images/logos/longevity-logo.png';
                logoWidth = '65%';
                break;
            case 48:
                logo = '/images/logos/lifetime_logo.png';
                logoWidth = '32%';
                break;
            case 11:
                logo = '/images/logos/formulahealth_logo.png';
                logoWidth = '50%';
                break;
            case 15:
                logo = '/images/logos/bodyology_logo.png';
                logoWidth = '45%';
                break;
            case 50:
                logo = '/images/logos/eterna_life.png';
                logoWidth = '40%';
                break;
            case 51:
                logo = '/images/logos/bevivo.png';
                logoWidth = '30%';
                break;
            case 52:
                logo = '/images/logos/biolimitless.png';
                logoWidth = '60%';
                break;
            default:
                logo = '/images/logo.svg'
                break;
        }
        this.setState({ logo, logoWidth, logoheight, objectFit, objectPosition, width, height })
        return logo

    }

    async changePartner(e) {
        let switchPartner = await apiService.switchPartner({ practitioner_id: this.state.id, partner_id: e.value });

        console.log("switchPartner", switchPartner)
        let user = this.props?.user
        if (user) {
            user.partner_id = e.value;
            this.props.updateUserData(user);
            this.setState({ selectedPartner: e });
            //window.location.reload();
        }
    }

    render() {
        const {
            modal,
            emailError,
            first_nameError,
            phoneError,
            phoneValid,
            user_nameError,
            loading,
            logo,
            logoWidth,
            availablePartners,
            selectedPartner,
            logoheight,
            objectPosition,
            objectFit,
            width,
            height,
            user_nameMessage,
            first_nameMessage,
            last_nameError,
            last_nameMessage,
        } = this.state;

        const { t } = this.props;
        const username = this.props?.user?.username || "";

        return this.state.practitioner_id && (
            <>
                <header>
                    <div className="container-wrp">
                        <div className="row justify-content-between">
                            <div className="col-4 col-md-3">
                                <div style={{ width: `${width}`, height: `${height}` }}>
                                    <img className="img-fluid" src={logo} style={{ width: `${logoWidth}`, height: `${logoheight}`, objectFit: `${objectFit}`, objectPosition: `${objectPosition}` }} alt="Logo" ></img>
                                </div>
                            </div>
                            {availablePartners && availablePartners.length > 0 &&
                                <div className="col-12 col-md-3 partner-select">
                                    <p>{t('partner')} :  </p>
                                    <Select
                                        className="select-options"
                                        aria-labelledby="aria-label"
                                        value={selectedPartner}
                                        inputId="aria-example-input"
                                        name="aria-live-color"
                                        options={availablePartners}
                                        onChange={(e) => this.changePartner(e)}
                                    />
                                </div>
                            }
                            <div className="col-auto col-md-auto d-flex align-items-center">

                                <UserMenu username={username} logout={(e) => this.handleexit} modal={() => this.handleModal}></UserMenu>
                                <button className="menuicon d-md-none"><img src="/images/hamburgericon.svg" alt="Menu"></img></button>
                            </div>
                        </div>
                    </div>
                </header>
                <Modal show={this.state.show} onHide={() => this.handleModal()} centered className="trackingModal" size="sm" >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('edit_profile')}:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-management-modal">
                        <Form>
                            <Form.Group className="sign-field">
                                <Form.Label>{t('email')}</Form.Label>
                                <Form.Control placeholder={t('placeholder.email')} type="email" name="email" value={modal.email} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {emailError ? t('validations.email') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>{t('username')}</Form.Label>
                                <Form.Control placeholder="{t('placeholder.username')}" type="text" name="user_name" value={modal.user_name}
                                    disabled
                                />
                                <Form.Text className="text-muted">
                                    {user_nameError ? user_nameMessage || t('validations.username') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>{t('labels.first_name')}</Form.Label>
                                <Form.Control placeholder={t('placeholder.first_name')} type="text" name="first_name" value={modal.first_name} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {first_nameError ? first_nameMessage || t('validations.first_name') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>{t('last_name')}</Form.Label>
                                <Form.Control placeholder={t('placeholder.last_name')} type="text" name="last_name" value={modal.last_name} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {last_nameError ? last_nameMessage || t('validations.last_name') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Form.Label>{t('labels.phone')}</Form.Label>
                                <Form.Control placeholder={t('placeholder.phone')} type="text" name="phone" value={modal.phone} onChange={(e) => this.handleChange(e)} required />
                                <Form.Text className="text-muted">
                                    {phoneError ? t('validations.phone') : null}
                                    {phoneValid ? t('validations.phone_format') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="sign-field">
                                <Button className="continuebtn" onClick={() => this.handleEdit()}>
                                    {t('edit_profile')}
                                    {loading ? <Spinner animation="border" variant="light" size="sm" style={{ float: 'center' }} className='spinnerClass' /> : null}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
};

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    updateUserData: userActions.updateUserData,
}
export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(Header);
import React from "react";
import { Form, Modal, Button, Spinner, Row, Col } from "react-bootstrap";
import { connect } from 'react-redux';
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB"; // Import British English locale
import tr from "date-fns/locale/tr";
import moment from 'moment';

import { apiService } from '../../services/api_service'
import { alertActions } from '../../actions';
import { SortArray, nameRegex, regexKit, genderList } from "../../constants";
import CustomerKitType from "./CustomerKitType";
import { getPartnerURLs } from "../../helpers/partner";

registerLocale('en', enGB);
registerLocale('tr', tr);

class EditUser extends React.Component {

    constructor(props) {
        super(props)
        console.log(props.UserDetails);
        this.state = {
            show1: props.show ? props.show : false,
            show2: false,
            fname: '',
            lname: '',
            email: '',
            phnum: '',
            dob: '',
            gender: '',
            kitId: '',
            bloodKitId: '',
            departments: '',
            selected_dep: '',
            partner: '',
            selected_clinic: '',
            relation: [],
            terms_condition: false,
            research: false,
            newsletter: false,
            error: {
                fname: false,
                lname: false,
                email: false,
                kitId: false,
                bloodKitId: false,
                kitIdSelect: false,
                phnum: false,
                dob: false,
                gender: false,
                age: false,
                usertype: false,
                partner: false,
                selected_dep: false,
                selected_clinic: false,
                practitioner: false,
                relationShip: false,
                terms_condition: false
            },
            emailErrorMsg: this.props.t('validations.email'),
            kitErrorMsg: this.props.t('validations.kit_id'),
            bloodKitErrMsg: this.props.t('validations.kit_id'),
            loader: false,
            userDetails: props.UserDetails ? props.UserDetails : {},
            alert: {
                message: this.props.t('messages.error.update_user'),
                type: 'alert-danger'
            },
            clinics: [],
            userType: [
                { label: this.props.t('user_types.partner'), value: 'Partner' },
                { label: this.props.t('user_types.clinic'), value: 'Clinic' },
                { label: this.props.t('user_types.practitioner'), value: 'Practitioner' }
            ],
            user_type: '',
            practitioner: '',
            practitionerList: [],
            show4: false,
            warning: false,
            privacy: "https://muhdo.com/privacy-policy/",
            terms: "https://muhdo.com/terms-conditions/",
            text: "Muhdo Health",
            blood_test_access: false,
            showGeneticKit: false,
            showBloodKit: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nameValidate = this.nameValidate.bind(this)
        this.EmailValidateHandler = this.EmailValidateHandler.bind(this)
        this.Validator = this.Validator.bind(this)
        this.kitIdValidateHandler = this.kitIdValidateHandler.bind(this)
        this.bloodKitIdValidateHandler = this.bloodKitIdValidateHandler.bind(this)
        this.DOBValidator = this.DOBValidator.bind(this)
        this.errorValidation = this.errorValidation.bind(this)
        this.phnValidator = this.phnValidator.bind(this)
        this.genderValidator = this.genderValidator.bind(this)
        this.handleDOBChange = this.handleDOBChange.bind(this)
        this.termsValidator = this.termsValidator.bind(this)
        this.handleBloodKitClick = this.handleBloodKitClick.bind(this)
        this.handleGeneticKitClick = this.handleGeneticKitClick.bind(this)
        this.saveCustomer = this.saveCustomer.bind(this)
    }

    async componentDidMount() {
        let user = this.props.user
        let practitioner_details = await apiService.practitionerDetails(user.email)
        let { privacy, terms, text } = getPartnerURLs(practitioner_details.partner_id)
        this.setState({ privacy, terms, text })
        await this.bloodTestAccess(this.props.access)
        console.log('component mounted')
        const { t } = this.props;
        let userType = [
            { label: t('user_types.practitioner'), value: 'Practitioner' }
        ]


        if (practitioner_details.type == 'admin') {
            userType = [
                { label: t('user_types.partner'), value: 'Partner' },
                { label: t('user_types.clinic'), value: 'Clinic' },
                { label: t('user_types.practitioner'), value: 'Practitioner' }
            ]
        }
        let partner_id = ''
        let clinic_id = ''
        let practitioner_id = ''
        let user_type = ''
        let departments = []
        if (practitioner_details && practitioner_details.type == 'partner') {
            partner_id = practitioner_details.partner_id
            departments = await apiService.departmentListAPI({ partner_id, practitioner_id: this.props.practitioner_id })
            departments = departments[0] ? departments[0].dept : []
            user_type = this.props.type == 'create' ? '' : 'Partner'
            userType = [
                { label: t('user_types.partner'), value: 'Partner' },
                { label: t('user_types.clinic'), value: 'Clinic' },
                { label: t('user_types.practitioner'), value: 'Practitioner' }
            ]
        }
        if (practitioner_details && practitioner_details.type == 'clinic') {
            partner_id = practitioner_details.partner_id
            clinic_id = practitioner_details._id
            departments = await apiService.departmentListAPI({ partner_id, clinic_id, practitioner_id: this.props.practitioner_id })
            departments = departments[0] ? departments[0].dept : []
            user_type = this.props.type == 'create' ? '' : 'Clinic'
            userType = [
                { label: t('user_types.clinic'), value: 'Clinic' },
                { label: t('user_types.practitioner'), value: 'Practitioner' }
            ]
        }
        if (practitioner_details && practitioner_details.type == 'practitioner') {
            partner_id = practitioner_details.partner_id
            clinic_id = practitioner_details.clinic_id
            practitioner_id = practitioner_details._id
            departments = await apiService.departmentListAPI({ partner_id, practitioner_id: this.props.practitioner_id })
            departments = departments[0] ? departments[0].dept : []
            user_type = this.props.type == 'create' ? '' : 'Practitioner'
            userType = [
                { label: t('user_types.practitioner'), value: 'Practitioner' }
            ]
        }
        if (practitioner_details && practitioner_details.type == 'admin') {
            userType = [
                { label: t('user_types.partner'), value: 'Partner' },
                { label: t('user_types.clinic'), value: 'Clinic' },
                { label: t('user_types.practitioner'), value: 'Practitioner' }
            ]
        }

        if (this.props.UserDetails.gender === 'Male') {
            this.props.UserDetails.gender = '';
        }
        // console.log(partner_id,clinic_id,practitioner_id,user_type,'hhhhhhhhhhhhhhhhhhhhhhhhh')
        this.setState({
            userType,
            user_type: this.props.UserDetails && this.props.UserDetails.user_type ? this.props.UserDetails.user_type : user_type,
            fname: this.props.UserDetails.first_name ? this.props.UserDetails.first_name : '',
            lname: this.props.UserDetails.last_name ? this.props.UserDetails.last_name : '',
            email: this.props.UserDetails.email ? this.props.UserDetails.email : '',
            phnum: this.props.UserDetails.phone ? this.props.UserDetails.phone : '',
            dob: this.props.UserDetails.birthday ? new Date(this.props.UserDetails.birthday) : '',
            gender: this.props.UserDetails.gender ? this.props.UserDetails.gender : '',
            departments: this.props.departments ? this.props.departments : departments,
            selected_dep: this.props.UserDetails ? this.props.UserDetails.department : '',
            partner: this.props.UserDetails.partner_id ? this.props.UserDetails.partner_id : partner_id,
            selected_clinic: this.props.UserDetails && this.props.UserDetails.clinic_id && this.props.UserDetails.clinic_id.length > 0 ? this.props.UserDetails.clinic_id[0] : clinic_id,
            clinics: this.props.subPartnerList ? this.props.subPartnerList : [],
            practitioner: this.props.UserDetails && this.props.UserDetails.practitioner_id && this.props.UserDetails.practitioner_id != "" ? this.props.UserDetails.practitioner_id[0] : practitioner_id,
            practitionerList: this.props.practitionerList ? this.props.practitionerList : [],
            terms_condition: this.props.UserDetails.terms_and_condition ? true : false,
            research: this.props.UserDetails.research_purpose ? true : false,
            newsletter: this.props.UserDetails.newsletter ? true : false,
            kitId: this.props.UserDetails.kit_id ? this.props.UserDetails.kit_id : '',
            relation: this.props.UserDetails.relation ? this.props.UserDetails.relation : [],
            bloodKitId: this.props.UserDetails?.blood_kit_id ?? '',
            relationbloodKits: this.props.UserDetails?.bloodKits ?? [],
        })
        console.log("state in component mount", this.state)
    }

    async bloodTestAccess(access) {
        let blood_test_access = false
        if (access?.blood_test)
            blood_test_access = true
        this.setState({ blood_test_access })
    }

    selectHandler(e) {
        let user_id = e.target.value
        this.props.DetailsGetAPI(user_id, this.props.page)

    }
    async handleChange(e) {
        let { name, value } = e.target;
        // console.log({ name, value });
        if (["kitId", "bloodKitId"].includes(name)) {
            value = value.toUpperCase();
        }
        this.setState({ [name]: value });

        setTimeout(() => {
            this.Validator(name);
        }, 0);
    }
    async handleDOBChange(date) {
        this.setState({ dob: date });
    }
    Validator(name) {
        switch (name) {
            case "fname":
                this.nameValidate(name);
                break;
            case "lname":
                this.nameValidate(name);
                break;
            case "phnum":
                this.phnValidator();
                break;
            case "dob":
                this.DOBValidator();
                break;
            case "email":
                this.EmailValidateHandler();
                break;
            case "gender":
                this.genderValidator();
                break;
            case "kitId":
                this.kitIdValidateHandler();
                break;
            case "bloodKitId":
                if (this.props?.access?.blood_test) {
                    this.bloodKitIdValidateHandler();
                }
                break;
            case "partner":
                this.deptValidator("partner");
                break;
            case "selected_dep":
                this.deptValidator("selected_dep");
                break;
            case "all":
            default:
                this.nameValidate("fname");
                this.nameValidate("lname");
                this.EmailValidateHandler();
                this.phnValidator();
                this.DOBValidator();
                this.KitTypeValidator();
                this.kitIdValidateHandler();
                if (this.props?.access?.blood_test) {
                    this.bloodKitIdValidateHandler();
                }
                this.userTypeValidator();
                this.termsValidator();
                this.genderValidator();
                break;
        }
    }

    nameValidate(name) {
        const { t } = this.props;
        const text = this.state[name].trim();
        // console.log("text in nameValidate", text, name);
        const fieldName = name === "fname" ? "first_name" : "last_name";

        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            if (!text.length) {
                updatedError[name] = true;
                updatedError[`${name}_message`] = t(`validations.${fieldName}`);
            } else if (!nameRegex.test(text)) {
                updatedError[name] = true;
                updatedError[`${name}_message`] = t(`validations.valid_${fieldName}`);
            } else {
                updatedError[name] = false;
                updatedError[`${name}_message`] = "";
            }

            return { error: updatedError }; // Update state based on previous state
        });
    }

    async phnValidator() {
        const phonenoRegex = /^\+\d+$/;

        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            // Validate phone number format
            updatedError.phnum = !phonenoRegex.test(prevState.phnum);

            return { error: updatedError };
        });
    }


    async deptValidator(name) {
        const updatedError = { ...this.state.error };

        updatedError[name] = !this.state[name] || this.state[name] === 'select';
        this.setState({ error: updatedError });
    }

    async termsValidator() {
        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            // Update terms_condition error based on the state
            updatedError.terms_condition = !prevState.terms_condition;

            return { error: updatedError };
        });
    }

    async EmailValidateHandler() {
        const { t, UserDetails } = this.props;
        const { email } = this.state;
        const regexEmail = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;

        // Clone the error state safely
        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            // Validate email format
            if (!email || !regexEmail.test(email)) {
                updatedError.email = true;
                return {
                    error: updatedError,
                    emailErrorMsg: t('validations.email')
                };
            }

            // If email matches UserDetails
            if (UserDetails.email === email) {
                updatedError.email = false;
                return { error: updatedError };
            }

            // Default case before API validation
            return { error: updatedError };
        });

        // Proceed with API validation if the format is valid and email is not the same as UserDetails
        if (regexEmail.test(email) && UserDetails.email !== email) {
            try {
                const isValid = await apiService.EmailValidator(email);

                this.setState(prevState => {
                    const updatedError = { ...prevState.error };
                    if (isValid) {
                        updatedError.email = false;
                        return { error: updatedError, emailErrorMsg: "" };
                    } else {
                        updatedError.email = true;
                        return {
                            error: updatedError,
                            emailErrorMsg: t('validations.email_exists')
                        };
                    }
                });
            } catch (error) {
                console.error("Error during email validation:", error);
                this.setState(prevState => ({
                    ...prevState,
                    emailErrorMsg: t('validations.error_occurred')
                }));
            }
        }
    }


    async handleGenderSelect(e) {
        const selectedGender = e.target.value;
        this.setState({ gender: selectedGender }); // Update gender state first

        // Validate the selected gender
        if (selectedGender === '' || selectedGender === 'select') {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    gender: true // Set error for gender
                }
            }));
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    gender: false // Clear error for gender
                }
            }));
        }
    }

    async handlePartnerSelect(e) {
        let type = e.target.name
        let value = e.target.value
        let { error } = this.state
        let departments = []
        let clinics = []
        let practitionerList = []
        if (value != 'select') {
            if (value) {
                departments = await apiService.departmentListAPI({ partner_id: value, practitioner_id: this.props.practitioner_id })
                // console.log(departments,'departments')
                departments = departments[0] ? departments[0].dept : []
            }
            if (this.state.user_type === 'Clinic' && this.props.practitioner_type === 'admin') {
                clinics = await apiService.ClinicList({ partner_id: value, practitioner_id: this.props.practitioner_id })
                // console.log(clinics,'departments')
                clinics = clinics.length ? clinics : []
            }
            if (this.state.user_type === 'Practitioner') {
                practitionerList = await apiService.practitionerList({ practitioner_id: this.props.practitioner_id, partner_id: value })
                // console.log(practitionerList,'practitioner')
                practitionerList = practitionerList.length ? practitionerList : []
                clinics = await apiService.ClinicList({ partner_id: value, practitioner_id: this.props.practitioner_id })
                // console.log(clinics,'departments')
                clinics = clinics.length ? clinics : []
            }
            error = { ...error, partner: false }
        }
        this.setState({ [type]: value, departments, clinics, selected_dep: '', selected_clinic: '', practitioner: '', practitionerList, error });
    }

    async handleSubPartnerSelect(e) {
        let type = e.target.name
        console.log(type, e, 'type')
        let value = e.target.value, error = this.state.error
        let departments = [], practitionerList = []

        if (value !== 'select') {
            departments = await apiService.departmentListAPI({ clinic_id: value, practitioner_id: this.props.practitioner_id, partner_id: this.state.partner })
            departments = departments[0] ? departments[0].dept : []

            if (this.state.user_type === 'Practitioner') {
                practitionerList = await apiService.practitionerList({ practitioner_id: btoa(value) })
                practitionerList = practitionerList ? practitionerList : []
            }
            error = { ...error, selected_clinic: false }
        }
        this.setState({ [type]: value, departments, selected_dep: '', practitioner: '', practitionerList, error });
    }

    async handleUserType(e) {
        let value = e.target.value
        console.log(value, this.state)
        let stateValues = { user_type: value }
        if (value === 'select' && e.target.name === 'Partner') {
            stateValues.selected_clinic = ''
            stateValues.selected_dep = ''
            stateValues.partner = ''
            stateValues.practitioner = ''

        } else if (value === 'select' && e.target.name === 'Clinic') {
            stateValues.selected_clinic = ''
            stateValues.selected_dep = ''
            stateValues.practitioner = ''

        } else if (value === 'select' && e.target.name === 'Practitioner') {
            stateValues.practitioner = ''
        }

        if (value === 'Partner' && this.props.practitioner_type === 'partner') {
            //  console.log(this.state.partner,'this.state.partner_id')
            let departmentList = await apiService.departmentListAPI({ partner_id: this.state.partner, practitioner_id: this.props.practitioner_id })
            departmentList = departmentList[0] ? departmentList[0].dept : []
            // console.log(departmentList,'departmentList')
            stateValues.departments = departmentList
        }
        if (value === 'Clinic' && this.props.practitioner_type === 'clinic') {
            // console.log(this.state.partner,'this.state.partner_id')
            let departmentList = await apiService.departmentListAPI({ partner_id: this.state.partner, practitioner_id: this.props.practitioner_id, clinic_id: this.state.selected_clinic })
            departmentList = departmentList[0] ? departmentList[0].dept : []
            //    console.log(departmentList,'departmentList')
            stateValues.departments = departmentList
        }
        stateValues.error = { ...this.state.error, usertype: false };
        // console.log(stateValues, "sssssssssssss")

        this.setState(stateValues)
    }
    async handleDeptSelect(e) {
        let value = e.target.value
        // console.log(value)
        this.setState({ selected_dep: value })
    }

    async handlePractitionerSelect(e) {
        let value = e.target.value, error = this.state.error;
        // console.log(value)
        if (value != 'select') {
            error = { ...error, practitioner: false }
        }
        this.setState({ practitioner: value, error })
    }

    async genderValidator() {
        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            // Validate gender field
            updatedError.gender = !prevState.gender || prevState.gender === 'select';

            return { error: updatedError };
        });
    }

    async userTypeValidator() {
        console.log(this.state, 'typeee', this.props.practitioner_type);

        const { practitioner_type } = this.props;

        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            // Validation logic
            if (practitioner_type !== 'practitioner') {
                if (!prevState.partner || prevState.partner === 'select') {
                    updatedError.partner = practitioner_type === 'partner' ? false : true;
                }
            }

            console.log("Updated error state:", updatedError);

            return { error: updatedError };
        });
    }

    async kitIdValidateHandler() {
        const { t, type } = this.props;

        if (type === 'create') {
            this.setState(prevState => {
                const updatedError = { ...prevState.error };

                // Case: Blood test access without genetic kit
                if (prevState.blood_test_access && !prevState.showGeneticKit) {
                    updatedError.kitId = false;
                    return { error: updatedError };
                }

                // Default return for other cases
                return null;
            });

            // Validation for kitId
            if (!this.state.kitId || !regexKit.test(this.state.kitId)) {
                this.setState(prevState => ({
                    error: { ...prevState.error, kitId: true },
                    kitErrorMsg: t('validations.kit_id')
                }));
                return;
            }

            // API validation
            try {
                const valid = await apiService.KitIdValidator(this.state.kitId);
                this.setState(prevState => ({
                    error: { ...prevState.error, kitId: valid.status === 200 ? false : true },
                    kitErrorMsg: valid.status === 200 ? '' : valid.message || t('validations.invalid_kit')
                }));
            } catch (error) {
                console.error('Kit ID validation error:', error);
                this.setState(prevState => ({
                    error: { ...prevState.error, kitId: true },
                    kitErrorMsg: t('validations.error_occurred')
                }));
            }
        } else {
            // For non-'create' cases
            this.setState(prevState => ({
                error: { ...prevState.error, kitId: false }
            }));
        }
    }

    async bloodKitIdValidateHandler() {
        const { t, type } = this.props;

        if (type === "create") {
            this.setState(prevState => {
                const updatedError = { ...prevState.error };

                // Check for blood test access and showBloodKit flag
                if (prevState.blood_test_access && !prevState.showBloodKit) {
                    updatedError.bloodKitId = false;
                    return { error: updatedError };
                }

                return null; // Continue validation
            });

            // If the blood kit checkbox is not checked, stop validation
            if (!this.state.showBloodKit)
                return;

            // Validate bloodKitId format
            if (!this.state.bloodKitId || !regexKit.test(this.state.bloodKitId)) {
                this.setState(prevState => ({
                    error: { ...prevState.error, bloodKitId: true },
                    bloodKitErrMsg: t('validations.kit_id'),
                }));
                return;
            }

            // API validation for bloodKitId
            try {
                const valid = await apiService.CheckAssignBloodKitStatus({
                    kit_id: this.state.bloodKitId,
                });

                this.setState(prevState => ({
                    error: { ...prevState.error, bloodKitId: valid.status === 200 ? false : true },
                    bloodKitErrMsg: valid.status === 200
                        ? ''
                        : valid.message || t('validations.invalid_kit'),
                }));
            } catch (error) {
                console.error('Error validating bloodKitId:', error);
                this.setState(prevState => ({
                    error: { ...prevState.error, bloodKitId: true },
                    bloodKitErrMsg: t('validations.error_occurred'),
                }));
            }
        } else {
            // For non-'create' types, directly set bloodKitId error to false
            this.setState(prevState => ({
                error: { ...prevState.error, bloodKitId: false },
            }));
        }
    }

    async DOBValidator() {
        const today = moment().format("YYYY-MM-DD");
        const dob = moment(this.state.dob, 'YYYYMMDD');
        const diff = moment().diff(dob, 'years');

        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            // Validate date of birth and age
            updatedError.dob = !prevState.dob || prevState.dob > today;
            updatedError.age = diff < 18 && !prevState.warning;

            return {
                error: updatedError,
                show4: updatedError.age
            };
        });
    }

    errorValidation() {
        let error_states = Object.keys(this.state.error).find(key => this.state.error[key] === true);
        if (error_states) {
            return false
        } else {
            return true
        }
    }

    async saveCustomer() {
        if (this.errorValidation()) {
            let req = {
                first_name: this.state.fname.trim(),
                last_name: this.state.lname.trim(),
                email: this.state.email.trim(),
                phone: this.state.phnum.trim(),
                birthday: moment(this.state.dob).format("MM-DD-YYYY"),
                gender: this.state.gender,
                terms_and_condition: this.state.terms_condition,
                partner_id: this.state.partner,
            };

            if (this.state.selected_dep && this.state.selected_dep !== "select") {
                req["department"] = btoa(this.state.selected_dep)
            }

            if (this.props?.type === "create") {
                if (!this.state.blood_test_access && this.state.kitId) {
                    req.kit_id = this.state.kitId;
                }

                if (this.state.blood_test_access) {
                    if (this.state.showGeneticKit && this.state.kitId) {
                        req.kit_type = "genetic";
                        req.kit_id = this.state.kitId;
                    }
                    if (this.state.showBloodKit && this.state.bloodKitId) {
                        req.kit_type = "blood";
                        req.blood_kit_id = this.state.bloodKitId;
                    }
                    if (this.state.showGeneticKit && this.state.showBloodKit) {
                        req.kit_type = "both";
                    }
                }
            }

            const { practitionerDetails } = this.props;

            if (practitionerDetails) {
                if (["admin", "partner"].includes(practitionerDetails.type)) {
                    req.user_type = "Partner";
                } else if (practitionerDetails.type === "clinic") {
                    req.user_type = "Clinic";
                } else if (practitionerDetails.type === "practitioner") {
                    req.user_type = "Practitioner";
                }
            }

            req.research_purpose = this.state.research || false;
            req.newsletter = this.state.newsletter || false;

            console.log(req, 'reqqqqqqqqqq');

            let resp = await this.props.updateAPI(req);

            if (req.blood_kit_id) {
                const body = {
                    user_id: Buffer.from(String(resp._id)).toString('base64'),
                    kit_id: req.blood_kit_id,
                };
                let bloodkitResp = await apiService.assignBloodKitToUser(body)
                console.log("bloodkitResp", bloodkitResp)
            }
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        //Todo validation

        // const { filter_type, practitioner_type } = this.props;
        // if (
        //   filter_type === "Practitioner" ||
        //   practitioner_type === "practitioner"
        // ) {
        //   console.log("kit validator");
        //   this.Validator("kitId");
        // } else {
        // this.Validator("all");
        // }

        this.Validator("all");
        setTimeout(() => {
            console.log(this.errorValidation(), 'errrrrrrrrrrr', this.state.error);
            this.saveCustomer()
        }, 0)
    }

    addUserHandler = () => {
        this.setState({ show1: true })
    }
    handleClose1 = () => {
        this.setState({
            fname: '',
            lname: '',
            email: '',
            phnum: '',
            dob: '',
            gender: '',
            kitId: '',
            bloodKitId: '',
            departments: '',
            selected_dep: '',
            partner: '',
            selected_clinic: '',
            user_type: '',
            relation: [],
            terms_condition: false,
            research: false,
            newsletter: false,
            error: {
                fname: false,
                lname: false,
                email: false,
                kitId: false,
                bloodKitId: false,
                kitIdSelect: false,
                phnum: false,
                dob: false,
                gender: false,
                partner: false,
                clinic: false,
                practitioner: false,
                selected_dep: false,
                selected_clinic: false,
                relationShip: false,
                terms_condition: false,
            },
        })
        this.props.closeModal()
    }
    handleShow1 = () => this.setState({ show1: true })
    handleClose = () => {
        this.props.clearAlert();
    }

    handleGeneticKitClick(event) {
        let updatedState = {
            ...this.state,
            showGeneticKit: event.target.checked,
        };
        if (!event.target.checked) {
            updatedState = {
                ...updatedState,
                kitId: "",
                error: { ...this.state.error, kitId: false },
                kitErrorMsg: "",
            };
        }
        this.setState({ ...updatedState }, () => {
            this.KitTypeValidator();
        });
    }
    handleBloodKitClick(event) {
        let updatedState = {
            ...this.state,
            showBloodKit: event.target.checked,
        };
        if (!event.target.checked) {
            updatedState = {
                ...updatedState,
                bloodKitId: "",
                error: { ...this.state.error, bloodKitId: false },
                bloodKitErrMsg: "",
            };
        }
        this.setState({ ...updatedState }, () => {
            this.KitTypeValidator();
        });
    }

    KitTypeValidator() {
        const { blood_test_access, showGeneticKit, showBloodKit } = this.state;

        // If blood_test_access is false or type is 'edit', exit early
        if (!blood_test_access || this.props.type === 'edit') return;

        this.setState(prevState => {
            const updatedError = { ...prevState.error };

            // Validate kit selection
            updatedError.kitIdSelect = !showGeneticKit && !showBloodKit;

            return { error: updatedError };
        });
    }

    render() {
        const {
            fname, lname, email, phnum, dob, gender, kitId, relation, error, emailErrorMsg,
            kitErrorMsg, alert, terms_condition, research, newsletter, show4, privacy, terms, partner,
            text, blood_test_access, showGeneticKit, bloodKitId, showBloodKit, bloodKitErrMsg, relationbloodKits
        } = this.state;
        const { show, showAlert, title, t } = this.props;
        const termsLinkClass = partner === 50 ? 'disabledLink' : '';

        // console.log(showGeneticKit, showBloodKit);

        return (
            <>
                <Modal show={show} onHide={this.handleClose1} centered className="userModal" size="sm" onEntered={() => this._input.focus()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form autoComplete='off'>
                            {showAlert &&
                                <div className={`alert ${alert.type}`} style={{ marginBottom: 15 }}>
                                    {alert.message}
                                    <button className="close" onClick={this.handleClose} >x</button>
                                </div>
                            }
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('labels.first_name')}</Form.Label>
                                <Form.Control type="text" placeholder={t('placeholder.first_name')} name='fname' value={fname} onChange={this.handleChange} required ref={input => this._input = input} />
                                <Form.Text className="text-muted">
                                    {error.fname ? error.fname_message || t('validations.first_name') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('labels.last_name')}</Form.Label>
                                <Form.Control type="text" placeholder={t('placeholder.last_name')} name='lname' value={lname} onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                    {error.lname ? error.lname_message || t('validations.last_name') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('labels.email_address')}</Form.Label>
                                <Form.Control type="email" placeholder={t('placeholder.email')} name='email' value={email} onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                    {error.email ? emailErrorMsg : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('labels.phone_number')}</Form.Label>
                                <Form.Control type="text" placeholder={t('placeholder.phone')} name='phnum' value={phnum} onChange={this.handleChange} />
                                <Form.Text className="text-muted">
                                    {error.phnum ? t('validations.phone_format') : null}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('labels.dob')}</Form.Label>
                                <div >
                                    <DatePicker
                                        className="form-control"
                                        selected={dob}
                                        onChange={this.handleDOBChange}
                                        onClickOutside={this.openDatePicker}
                                        maxDate={new Date()}
                                        locale={this.props.i18n.language}
                                        placeholderText="DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                    ></DatePicker>
                                </div>


                                <Form.Text className="text-muted">
                                    {error.dob ? t('validations.dob') : null}
                                    {error.age ?
                                        <Modal show={show4} onHide={() => { this.setState({ show4: false, warning: true }) }} className="dob-warning" centered size="sm" >
                                            <Modal.Header closeButton className="dob-warning-head">
                                                <Modal.Title>{t('title.warning')}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="user-management-modal">
                                                <div dangerouslySetInnerHTML={{ __html: t('html.dob_warning') }} />
                                                <Button style={{ marginLeft: "34%", marginTop: "2%" }} className="bgBtn mr-3" type="submit" size='sm' onClick={() => { this.setState({ show4: false, warning: true }) }}>
                                                    {t('buttons.ok')}
                                                </Button>
                                            </Modal.Body>
                                        </Modal> : null}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('labels.select_gender')}</Form.Label>
                                <Form.Control as="select" name='gender' value={gender} className="multiselect-patients" onChange={(e) => this.handleGenderSelect(e)}>
                                    <option selected value='select'>--{t('labels.select_gender')}--</option>
                                    {genderList() && SortArray(genderList())?.map(gender => (
                                        <option key={gender.key} value={gender.value}>{gender.label}</option>)
                                    )}

                                </Form.Control>
                                <Form.Text className="text-muted">
                                    {error.gender && t('validations.gender')}
                                </Form.Text>
                            </Form.Group>

                            <CustomerKitType
                                {...{
                                    error,
                                    kitId,
                                    kitErrorMsg,
                                    handleChange: this.handleChange,
                                    type: this.props.type,
                                    relation,
                                    blood_test_access,
                                    bloodKitId,
                                    bloodKitErrMsg,
                                    showGeneticKit,
                                    showBloodKit,
                                    handleGeneticKitClick: this.handleGeneticKitClick,
                                    handleBloodKitClick: this.handleBloodKitClick,
                                    relationbloodKits,
                                }}
                            />

                            {this.props.practitioner_type == 'admin' && this.props.type == 'edit' &&
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{t('labels.select_partner')}</Form.Label>
                                    <Form.Control as="select" name='partner' value={this.state.partner} disabled className="multiselect-patients" onChange={(e) => this.handlePartnerSelect(e)}>
                                        <option selected value='select'>--{t('labels.select_partner')}--</option>
                                        {this.props?.partnerList && SortArray(this.props.partnerList)?.map(user => (
                                            <option key={user._id} value={user.partner_id}>{user.first_name} {user.last_name}</option>)
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            }
                            {this.props.practitioner_type === 'admin' && this.props.type === 'create' &&
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{t('labels.select_partner')}</Form.Label>
                                    <Form.Control as="select" name='partner' value={this.state.partner} className="multiselect-patients" onChange={(e) => this.handlePartnerSelect(e)}>
                                        <option selected value='select'>--{t('labels.select_partner')}--</option>
                                        {this.props?.partnerList && SortArray(this.props.partnerList)?.map(user => (<option key={user._id} value={user.partner_id}>{user.first_name} {user.last_name}</option>))}
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        {error.partner ? t('labels.select_partner') : null}
                                    </Form.Text>
                                </Form.Group>
                            }
                            {/* {
                               (this.props.practitioner_type =='admin' || this.props.practitioner_type =='partner') && (user_type =='Clinic' || user_type =='Practitioner') &&
                                <Form.Group controlId="formBasicEmail">
                                <Form.Label>Select Sub-Partner/ Clinic</Form.Label>
                                <Form.Control as="select" value={this.state.selected_clinic} name ='selected_clinic' className="multiselect-patients" onChange={(e) => this.handleSubPartnerSelect(e)}>
                                    <option selected value ='select'>--Select Sub-Partner/ Clinic--</option>
                                        {this.state.clinics&& this.state.clinics.map(user =>(
                                            <option key={user._id} value={user._id}>{user.first_name} {user.last_name}</option>)
                                            )}
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    {error.selected_clinic ? 'Select Clinic' : null}
                                </Form.Text>
                                </Form.Group>
                            } */}
                            {(this.state.partner || this.state.selected_clinic) &&
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{t('labels.custom_filter')}</Form.Label>
                                    <Form.Control as="select" value={this.state.selected_dep} name='selected_dep' className="multiselect-patients" onChange={(e) => this.handleDeptSelect(e)}>
                                        <option selected value='select'>--{t('labels.select_custom_filter')}--</option>
                                        {
                                            this.state?.departments && SortArray(this.state.departments, "department")?.map(dep => (
                                                <option key={dep._id} value={dep._id}>{dep.department}</option>)
                                            )}
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        {error.selected_dep ? t('validations.custom_filter') : null}
                                    </Form.Text>
                                </Form.Group>
                            }
                            {/* { (this.props.practitioner_type !='practitioner' && user_type =='Practitioner') &&
                                <Form.Group controlId="formBasicEmail">
                                <Form.Label>Select Practitioner</Form.Label>
                                <Form.Control as="select" name ='practitioner' value={this.state.practitioner} className="multiselect-patients" onChange={(e) => this.handlePractitionerSelect(e)}>
                                    <option selected value ='select'>--Select Practitioner--</option>
                                        {this.state.practitionerList&& this.state.practitionerList.map(user =>(
                                            <option key={user._id} value={user._id}>{user.first_name} {user.last_name}</option>)
                                            )}
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    {error.practitioner ? 'Select Practitioner' : null}
                                </Form.Text>
                                </Form.Group>
                            } */}
                            {/* Terms and Conditions radio button */}
                            <Form.Group controlId="formBasicCheckbox" className="customRadio">
                                <input
                                    checked={terms_condition}
                                    onClick={() => this.setState({ terms_condition: !terms_condition })}
                                    type="radio"
                                    className="termsRadioBtn"
                                    label={t('labels.t_and_c')}
                                />

                                <Form.Label className="radioForm">
                                    <div className={`linkStyle ${termsLinkClass}`} dangerouslySetInnerHTML={{
                                        __html: t('html.terms_conditions', {
                                            terms: terms,
                                            privacy: privacy
                                        })
                                    }} />

                                </Form.Label>
                                <Form.Text className="text-muted">
                                    {error.terms_condition ? t('validations.t_and_c') : null}
                                </Form.Text>
                            </Form.Group>

                            {/* Data for research purpose radio button */}
                            <Form.Group controlId="formBasicCheckbox" className="customRadio">
                                <input
                                    checked={research}
                                    onClick={() => { this.setState({ research: !this.state.research }) }}
                                    type="radio" label={t('labels.research_purpose')}
                                    className="termsRadioBtn" />
                                <Form.Label className="radioForm">
                                    {t('text.research_purpose', { partner: text })}
                                    <span className="italicText"> {t('text.research_note')}</span>
                                </Form.Label>
                            </Form.Group>

                            {/* Newsletter radio button */}
                            <Form.Group controlId="formBasicCheckbox" className="customRadio">
                                <input
                                    checked={newsletter}
                                    onClick={() => { this.setState({ newsletter: !this.state.newsletter }) }}
                                    type="radio"
                                    label="Newsletter"
                                    className="termsRadioBtn" />
                                <Form.Label className="radioForm">
                                    {t('text.newsletter_label')}
                                    <span className="italicText">{t('text.newsletter_note')}</span>
                                </Form.Label>
                            </Form.Group>

                            <Button className="continuebtn" type="submit" size='sm' onClick={this.handleSubmit}>
                                {this.props.submitted ? <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style={{ float: 'center' }} /> : t('buttons.submit')}
                            </Button>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert, user };
}

const actionCreators = {
    clearAlerts: alertActions.clear
}

export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(EditUser);
import React from "react";
import { withTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { BsPencilFill, BsCheckLg } from "react-icons/bs";

class SignupForm extends React.Component {
    constructor(props) {

        super(props)

        this.state = {
            heightCm: "165",
            heightFt: "5",
            heightIn: "5",
            heightUnit: "cm",
            weightUnit: "lbs",
            weightLbs: "164",
            weightKg: "74",
            weightGm: "8",
            gender: this.props.gender,
            user_id: "",
            email: "",
            country: "",
            countryError: "",
            phone_edit: false,
            hide: true,
            phone_code: "",
            phoneError: false,
            phoneMsg: "",
            passwordFormat: false,
            hide1: true,
            confirmError: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editPhone = this.editPhone.bind(this);
    }

    editPhone() {
        this.setState({ phone_edit: !this.state.phone_edit });
    }
    async handleSubmit(e) {
        const { phoneError, countryError, country, heightCm, heightIn, heightFt, heightUnit, weightUnit, weightKg, weightGm, weightLbs, gender } = this.state;
        if (country === "") {
            this.setState({ countryError: true });
        }
        if (phoneError || countryError) {
            return;
        }
        await this.props.handleSubmit({ country, heightCm, heightIn, heightFt, heightUnit, weightUnit, weightKg, weightGm, weightLbs, gender })
    }
    handleChange(e) {
        const { name, value } = e.target;
        console.log("name", name, "val", value);
        this.setState({ [name]: value });
    }

    handlePhoneChange(e) {
        const { value } = e.target;
        const { phone_code } = this.state;
        let phoneError = false, phoneMsg = "";

        if (!value.startsWith(phone_code)) {
            phoneError = true;
            phoneMsg = this.props.t('validations.phone_code', { phone_code })
        }
        this.setState({ phoneError, phoneMsg });
        this.props.handleChange(e);
    }
    handleCountryChange(e) {
        const { name, value } = e.target;
        const { countries, phone } = this.props;
        console.log("name", name, "val", value);
        this.setState({ [name]: value });
        if (value !== "") {
            let phoneError = false, phoneMsg = "";
            let phone_code = countries.find(c => c._id == value).phone_code;
            phone_code = "+" + phone_code;
            console.log(phone_code, phone)
            if (!phone.startsWith(phone_code)) {
                phoneError = true;
                phoneMsg = this.props.t('validations.phone_code', { phone_code })
            }
            this.setState({ countryError: false, phoneError, phoneMsg, phone_code });
        } else {
            this.setState({ countryError: true })
        }
    }

    render() {
        const { heightCm, heightFt, heightIn, heightUnit, weightUnit, weightLbs, countryError,
            weightKg, weightGm, phoneError, phoneMsg, phone_edit, country } = this.state;
        const { countries, phone, gender, themeColor, t } = this.props;

        return (
            <>
                <h1>{t('complete_signup')}</h1>
                <h6>{t('signup_enter_details')}</h6>
                <Form>
                    <Form.Label>{t('labels.country')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        <Form.Control style={{ 'margin-left': "0px" }} as="select" name="country" value={country} onChange={this.handleCountryChange}>
                            <option value="">{t('select_country')}</option>
                            {countries.map(c =>
                                <option key={c._id} value={c._id}>{c.name}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Text className="text-muted">
                        {countryError ? t('validations.country') : null}
                    </Form.Text>
                    <Form.Label>{t('labels.phone_number')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        <Form.Control placeholder={t('labels.phone')} type="text" name="phone" value={phone} required disabled={!phone_edit} onChange={this.handlePhoneChange} />
                        <span onClick={this.editPhone}>
                            {phone_edit ? <BsCheckLg color={themeColor} size={25}></BsCheckLg>
                                : <BsPencilFill color={themeColor} size={25}></BsPencilFill>}
                        </span>
                    </Form.Group>
                    <Form.Text className="text-muted">
                        {phoneError ? phoneMsg : null}
                    </Form.Text>
                    <Form.Label>{t('labels.height')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        {heightUnit === "cm" ?
                            <Form.Control placeholder="Height" type="number" min={12} max={275} name="heightCm" value={heightCm} required onChange={this.handleChange} />
                            :
                            <>
                                <Form.Control placeholder="Height" type="number" min={1} max={8} name="heightFt" value={heightFt} required onChange={this.handleChange} /><span>{t('labels.height_ft')}</span>
                                <Form.Control placeholder="Height" type="number" min={0} max={11} name="heightIn" value={heightIn} required onChange={this.handleChange} /> <span>{t('labels.height_in')}</span>

                            </>
                        }

                        <select name="heightUnit" onChange={this.handleChange}>
                            <option value="cm">{t('height_options.cm')}</option>
                            <option value="ft">{t('height_options.ft')}</option>
                        </select>
                    </Form.Group>
                    <Form.Label>{t('labels.weight')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        {weightUnit === "lbs" ?
                            <Form.Control placeholder={t('placeholder.weight')} type="number" min={12} max={275} name="weightLbs" value={weightLbs} required onChange={this.handleChange} />
                            :
                            <>
                                <Form.Control inline="true" placeholder={t('placeholder.weight')} type="number" min={1} max={200} name="weightKg" value={weightKg} required onChange={this.handleChange} /><span>.</span>
                                <Form.Control inline="true" placeholder={t('placeholder.weight')} type="number" min={0} max={9} name="weightGm" value={weightGm} required onChange={this.handleChange} />
                            </>
                        }

                        <select name="weightUnit" onChange={this.handleChange}>
                            <option value="lbs">{t('weight_options.lbs')}</option>
                            <option value="kg">{t('weight_options.kg')}</option>
                        </select>
                    </Form.Group>
                    <Form.Label>{t('labels.gender')}</Form.Label>
                    <Form.Group className="sign-field input-group">
                        <Form.Check inline type='radio' name="gender" defaultChecked={gender === 'male'} value="male" label={t('gender.male')} className="signup-radio" onChange={this.handleChange} required />
                        <Form.Check inline type='radio' name="gender" defaultChecked={gender === 'female'} value="female" label={t('gender.female')} className="signup-radio" onChange={this.handleChange} required />
                    </Form.Group>
                    <Button className="submitbtn" onClick={this.handleSubmit}>
                        {t('buttons.submit')}
                    </Button>
                </Form>
            </>
        );
    }
};

export default withTranslation()(SignupForm);
